// @ts-ignore
import type * as RCL from 'rclnodejs';

import { getRCL } from '@sb/ros/getRCL';

import { BaseService } from './BaseService';

export class FKCheckService extends BaseService<
  'standard_bots_msgs/srv/FKCheck',
  RCL.standard_bots_msgs.srv.FKCheck_Request,
  RCL.standard_bots_msgs.srv.FKCheck_Response
> {
  private joint_state: RCL.sensor_msgs.msg.JointState;

  private check_collision: boolean;

  public constructor(
    joint_state: RCL.sensor_msgs.msg.JointState,
    check_collision: boolean,
  ) {
    super();
    this.joint_state = joint_state;
    this.check_collision = check_collision;
  }

  protected override getType(): RCL.TypeClass<keyof RCL.ServicesMap> {
    return 'standard_bots_msgs/srv/FKCheck';
  }

  protected override getServiceName() {
    return 'solve_fk';
  }

  protected override async createRequest() {
    const rcl = await getRCL();

    const FKCheck = rcl.require('standard_bots_msgs/srv/FKCheck');

    const request = new FKCheck.Request();

    request.joint_state = this.joint_state;
    request.check_collision = this.check_collision;

    return request;
  }
}
