// @ts-ignore
import type * as RCL from 'rclnodejs';

import { info, namespace } from '@sb/log';
import { getNode } from '@sb/ros/getNode';

const ns = namespace('BaseService');

/**
 * Base service class for sending goals to ROS2 service servers.
 *
 * To use, extend this class, then override the following methods:
 * - `getType()`: Return the type of the Service as a string.
 * - `getServiceName()`: Return the name of the Service as a string.
 * - `createRequest()`: Generate a request to call to the service.
 */
export abstract class BaseService<
  ServiceType extends RCL.TypeClass<RCL.ServiceTypeClassName>,
  RequestType extends RCL.ServiceRequestMessage<ServiceType>,
  ResponseType extends RCL.ServiceResponseMessage<ServiceType>,
> {
  public static WAIT_FOR_SERVER_TIMEOUT = 3;

  // cache of the Service client for each class
  private static clientMap: Map<string, any> = new Map<string, any>();

  // remove the Service client from the cache, used mainly for testing
  public static deleteClient(className: string) {
    BaseService.clientMap.delete(className);
  }

  public async call(): Promise<ResponseType> {
    const client = await this.getClient();

    if (!(await client.waitForService(BaseService.WAIT_FOR_SERVER_TIMEOUT))) {
      throw new Error('Service server not available');
    }

    const request: RequestType = await this.createRequest();

    info(ns`sendRequest`, 'sending service request', request.toPlainObject());

    return new Promise((resolve, reject) => {
      client.sendRequest(
        request,
        (response: RCL.ServiceResponseMessage<ServiceType>) => {
          if (response) {
            resolve(response as ResponseType);
          } else {
            reject(new Error('Failed to get a response'));
          }
        },
      );
    });
  }

  /**
   * Generate a request to call to the service.
   */
  protected createRequest(): Promise<RequestType> {
    throw new Error('Method not implemented.');
  }

  protected async getClient(): Promise<RCL.Client<ServiceType>> {
    let client = BaseService.clientMap.get(this.constructor.name);

    if (!client) {
      client = (await getNode()).createClient(
        this.getType(),
        this.getServiceName(),
      );

      BaseService.clientMap.set(this.constructor.name, client);

      info(ns`sendCall`, 'waiting for Service server...');

      await client.waitForService(BaseService.WAIT_FOR_SERVER_TIMEOUT);
    }

    return client;
  }

  /**
   * Get the ros2 Service type as a string
   * example: 'standard_bots_msgs/srv/IKCollisionCheck'
   */
  protected abstract getType(): RCL.TypeClass<keyof RCL.ServicesMap>;

  /**
   * Get the ros2 Service name as a string
   * example: 'solve_ik'
   */
  protected abstract getServiceName(): string;
}
