type HasNestedSteps<T> = {
  steps?: T[];
} & object;

/** Flat list containing all steps created for a routine. */
export const listAllSteps = <T extends HasNestedSteps<T>>(steps: T[]): T[] => {
  const allSteps: T[] = [];

  const getNestedSteps = (step: T) => {
    allSteps.push(step);

    if (step.steps) {
      step.steps.forEach(getNestedSteps);
    }
  };

  steps.forEach(getNestedSteps);

  return allSteps.flat(Infinity) as T[];
};
