import { CNCMonitorMachineStep } from '../implementations/CNCMachine/steps/CNCMonitorMachine/Step';
import { CNCOperateAutodoorStep } from '../implementations/CNCMachine/steps/CNCOperateAutodoor/Step';
import { CNCOperateWorkholdingStep } from '../implementations/CNCMachine/steps/CNCOperateWorkholding/Step';
import { CNCRunProgramStep } from '../implementations/CNCMachine/steps/CNCRunProgram/Step';
import { MoveVerticalLiftStep } from '../implementations/dynamic-base/steps/MoveVerticalLift/Step';
import { ActuateGripperStep } from '../implementations/gripper-general/steps/ActuateGripper/Step';
import { HaasControlRegionStep } from '../implementations/HaasMill/steps/HaasControlRegion/Step';
import { HaasRunProgramStep } from '../implementations/HaasMill/steps/HaasRunProgram/Step';
import { ActuateScrewdriverStep } from '../implementations/onRobot/OnRobotScrewdriver/steps/ActuateScrewdriver/Step';
import { ActuateVacuumStep } from '../implementations/onRobot/OnRobotVGP20/steps/ActuateVacuum/Step';
import { WeldStep } from '../implementations/WeldMachine/shared/steps/Step';

/**
 * Step definitions (including associated types) for controlling
 * devices from routines.
 */
export const StepDefinitions = {
  ActuateGripper: ActuateGripperStep,
  ActuateScrewdriver: ActuateScrewdriverStep,
  ActuateVacuum: ActuateVacuumStep,
  CNCRunProgram: CNCRunProgramStep,
  CNCMonitorMachine: CNCMonitorMachineStep,
  CNCOperateAutodoor: CNCOperateAutodoorStep,
  CNCOperateWorkholding: CNCOperateWorkholdingStep,
  HaasControlRegion: HaasControlRegionStep,
  HaasRunProgram: HaasRunProgramStep,
  MoveVerticalLift: MoveVerticalLiftStep,
  Weld: WeldStep,
} as const;
