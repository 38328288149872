import { CNCMachineCommand } from '../implementations/CNCMachine/types';
import { CustomGripperCommand } from '../implementations/CustomGripper/types';
import { DHAG105145Command } from '../implementations/dh/instances/DHAG_105_145/Command';
import { DHCGI100170Command } from '../implementations/dh/instances/DHCGI_100_170/Command';
import { DHPGC30060Command } from '../implementations/dh/instances/DHPGC_300_60/Command';
import { EwellixLiftTLTCommand } from '../implementations/EwellixLiftTLT/types';
import { ModbusTCPServerCommand } from '../implementations/ModbusTCPServer/command';
import { OnRobot2FG14Command } from '../implementations/onRobot/OnRobot2FG14/types';
import { OnRobot2FG7Command } from '../implementations/onRobot/OnRobot2FG7/types';
import { OnRobot3FG15Command } from '../implementations/onRobot/OnRobot3FG15/types';
import { OnRobotDualQuickChangerCommand } from '../implementations/onRobot/OnRobotDualQuickChanger/types';
import { OnRobotScrewdriverCommand } from '../implementations/onRobot/OnRobotScrewdriver/types';
import { OnRobotVGP20Command } from '../implementations/onRobot/OnRobotVGP20/types';
import { SchunkEGxCommand } from '../implementations/schunk/SchunkEGx/types/SchunkEGxCommand';
import { WeldMachineCommand } from '../implementations/WeldMachine/shared/types/WeldMachineCommand';

/**
 * Commands are send from either remote control or routine runner to the device
 * to perform some action.
 * Devices should check that supplied commands match their expected type
 * and ignore commands that they don't understand.
 * Commands must be JSON serializable and have a 'kind' attribute (string).
 */
export const DeviceCommandsRegistry = [
  OnRobot3FG15Command,
  OnRobot2FG7Command,
  OnRobot2FG14Command,
  OnRobotDualQuickChangerCommand,
  OnRobotScrewdriverCommand,
  OnRobotVGP20Command,
  EwellixLiftTLTCommand,
  ModbusTCPServerCommand,
  DHAG105145Command,
  DHPGC30060Command,
  DHCGI100170Command,
  SchunkEGxCommand,
  CNCMachineCommand,
  WeldMachineCommand,
  CustomGripperCommand,
] as const;
