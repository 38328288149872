import { cloneDeep } from 'lodash';
import * as zod from 'zod';

import {
  BlendConfig,
  MotionKind,
  TCPOptionOrAuto,
  forwardKinematics,
} from '@sb/motion-planning';
import {
  ActionRequiredError,
  MotionSpeed,
  Step,
} from '@sb/remote-control/types';
import { calculateSpeedProfileFromStepMotionSpeed } from '@sb/remote-control/util/calculateSpeedProfileFromStepMotionSpeed';
import convertJavaScriptExpressionToUser from '@sb/remote-control/util/expressions/convertJavaScriptExpressionToUser';
import { convertPythonExpression } from '@sb/remote-control/util/expressions/v2';
import { validateCodeExpression } from '@sb/remote-control/util/expressions/validateCodeExpression';
import { findStep } from '@sb/remote-control/util/findStep';
import { getStepKindInfo } from '@sb/remote-control/util/stepKindInfo';
import { validateMotionSpeed } from '@sb/remote-control/util/validators';
import {
  Expression,
  Space,
  type MoveArmToStepArguments,
  type SpeedProfile,
} from '@sb/routine-runner';

/**
 * Types matching the routine runner schema for "MoveArmTo" steps.
 */
export namespace MoveArmToTargetStep {
  export const name = 'Move arm';
  export const description =
    'Specify a location that the robot should go to by defining the joint or gripper positions';
  export const librarySection = Step.LibrarySection.Basic;
  export const librarySort = '1';
  export const argumentKind = 'MoveArmTo';

  /**
   * Where the shape of the step configuration changes,
   * this function migrates the configuration data to the new shape
   */

  const argumentsMigration = (argsIn: any) => {
    let args = argsIn;

    if (args?.argumentKind === argumentKind) {
      if (args.target) {
        args = cloneDeep(args);

        if (args.target.motionKind) {
          if (!args.motionKind) {
            args.motionKind = args.target.motionKind;
          }

          args.target.motionKind = undefined;
        }

        if (!args.target.jointAngles) {
          args.target = null;
        } else if (!args.target.pose) {
          args.target.pose = forwardKinematics(args.target.jointAngles);
        }
      }

      if (args.shouldMatchJointAngles === undefined) {
        args = {
          ...args,
          shouldMatchJointAngles: args.motionKind === 'joint',
        };
      }

      if (args.tcpOption === undefined) {
        args = {
          ...args,
          tcpOption: args.tcpOffsetOption,
        };
      }
    }

    return args;
  };

  export const Arguments = zod.preprocess(
    argumentsMigration,
    zod.object({
      argumentKind: zod.literal('MoveArmTo'),
      expression: Expression.optional(),
      motionKind: MotionKind.default('joint'),
      shouldMatchJointAngles: zod.boolean().default(true),
      tcpOption: TCPOptionOrAuto.default('auto'),
      motionSpeed: MotionSpeed.optional(),
      positionListID: zod.string().nullable().default(null),
      positionListIndex: zod.number().optional(),
      target: Space.Position.nullable().default(null),
      targetKind: zod
        .union([
          zod.literal('singlePosition'),
          zod.literal('positionList'),
          zod.literal('expression'),
        ])
        .default('singlePosition'),
      isWaypoint: zod.boolean().default(false),
      isCacheable: zod.boolean().default(false),
      blend: BlendConfig.optional(),
      reduceSmoothing: zod.boolean().default(false),
      moveDynamicBaseToReachPosition: zod.boolean().default(true),
    }),
  );

  export type Arguments = zod.infer<typeof Arguments>;

  export const validator: Step.Validator = ({
    step,
    stepConfiguration,
    routine: { steps, space },
    globalSpace,
  }) => {
    const spaceItems = [...globalSpace, ...space];
    const args = stepConfiguration?.args;

    if (args?.argumentKind !== argumentKind) {
      return;
    }

    // checkValidPositionConfig

    const hasTarget = !!(args.target?.jointAngles || args.target?.pose);

    if (args.targetKind === 'singlePosition' && !hasTarget) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Joint angles or pose must be set',
        fieldId: 'jointAngles',
      });
    }

    // checkPositionListDefined

    if (args.targetKind === 'positionList') {
      if (!args.positionListID) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Position list must be set',
          fieldId: 'positionListID',
        });
      }

      if (!spaceItems.some((s) => s.id === args.positionListID)) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Set the Position list linked to Move Arm step1.',
        });
      }
    }

    if (args.targetKind === 'expression' && args.expression != null) {
      validateCodeExpression(
        args.expression,
        'expression',
        'Position expression',
      );
    }

    // checkAddOffsetJointAngles

    const hasAddOffsetParentStep = step.parentSteps.some(
      (parentStep) => parentStep?.stepKind === 'AddOffset',
    );

    if (hasAddOffsetParentStep && (args.shouldMatchJointAngles ?? false)) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message:
          'Move Arm steps inside an Add Offset step may not require exact final joint position, as only the tooltip pose can be guaranteed.',
        fieldId: 'shouldMatchJointAngles',
      });
    }

    // checkWaypoints

    if (args.isWaypoint) {
      let isWaypointFound = false;

      // find first step after waypoint which isn't a decorator step
      const firstNonDecoratorStepAfterWaypoint = findStep(steps, (step1) => {
        if (step1.id === stepConfiguration?.id) {
          isWaypointFound = true;

          return undefined;
        }

        return isWaypointFound && !getStepKindInfo(step1.stepKind).isDecorator;
      });

      if (
        !firstNonDecoratorStepAfterWaypoint ||
        firstNonDecoratorStepAfterWaypoint.stepKind !== 'MoveArmTo'
      ) {
        throw new ActionRequiredError({
          kind: 'invalidConfiguration',
          message: 'Waypoint steps must have a terminal Move Arm step.',
        });
      }
    }

    // checkPushModeMoveIsLinear

    const isPushModeMove = step.parentSteps.some((parentStep) => {
      return parentStep?.stepKind === 'PushMode';
    });

    if (isPushModeMove && args.motionKind !== 'line') {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message:
          "Push mode move steps must be linear.  Set 'Move in a straight line' option to true",
      });
    }
  };

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    baseSpeedProfile,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const {
      expression,
      target,
      targetKind,
      motionKind,
      shouldMatchJointAngles,
      reduceSmoothing,
      tcpOption,
      motionSpeed,
      positionListID,
      positionListIndex,
      isWaypoint,
      isCacheable,
      blend,
      moveDynamicBaseToReachPosition,
    } = args;

    if (!motionKind) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Motion kind is not configured.',
        fieldId: 'motionKind',
      });
    }

    validateMotionSpeed('Move Arm', motionSpeed);

    const speedProfile: SpeedProfile = calculateSpeedProfileFromStepMotionSpeed(
      {
        motionSpeed,
        baseSpeedProfile,
      },
    );

    const getTarget = (): MoveArmToStepArguments['target'] => {
      switch (targetKind) {
        case 'positionList':
          if (positionListID) {
            return {
              positionListID,
              positionListIndex,
            };
          }

          break;

        case 'expression':
          if (expression) {
            return { expression };
          }

          break;

        default:
          if (target) {
            return target;
          }
      }

      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Target is not configured',
      });
    };

    return {
      ...stepData,
      stepKind: 'MoveArmTo',
      args: {
        motionKind,
        shouldMatchJointAngles,
        reduceSmoothing,
        tcpOption,
        speedProfile,
        target: getTarget(),
        isWaypoint: isWaypoint ?? false,
        isCacheable: isCacheable ?? false,
        blend,
        moveDynamicBaseToReachPosition,
      },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
    routine,
    includeStepName,
    globalSpace,
  }) => {
    if (args?.argumentKind !== argumentKind || !routine) {
      return null;
    }

    if (args.targetKind === 'singlePosition') {
      // arbitrary specified point
      return `${includeStepName ? 'Move arm ' : ''}to manually specified position`;
    }

    const allSpace = [...(globalSpace ?? []), ...routine.space];

    if (args.targetKind === 'positionList') {
      const space = allSpace.find((item) => item.id === args.positionListID);

      if (!space) {
        return null;
      }

      if (space.kind === 'singlePosition') {
        return `${includeStepName ? 'Move arm ' : ''}to ${space.name}`;
      }

      if (space.kind === 'safeHomePose') {
        return `${includeStepName ? 'Move arm to home pose' : ''}`;
      }

      if (
        space.kind === 'freeformPositionList' ||
        space.kind === 'gridPositionList'
      ) {
        if (args.positionListIndex == null) {
          return `${includeStepName ? 'Move arm ' : ''}to the next position in ${
            space.name
          }`;
        }

        return `${includeStepName ? 'Move arm ' : ''}to position ${
          args.positionListIndex
        } in ${space.name}`;
      }

      return null;
    }

    if (args.targetKind === 'expression') {
      if (args.expression == null) {
        return null;
      }

      // only JS+python expressions supported for now - so this should never be true
      // but we check anyways to satisfy the type checker
      if (args.expression.kind === 'JavaScript') {
        // This looks pretty gnarly for most expressions. Probably want to allow
        // the user to build up the expression using a DSL that we can desugar
        // into JS later.
        const jsExprStr = convertJavaScriptExpressionToUser(
          args.expression.expression,
          { isForStepDescription: true },
        );

        return `${includeStepName ? 'Move arm ' : ''}based on expression ${jsExprStr}`;
      }

      if (args.expression.kind === 'Python') {
        const { code: pyExprStr } =
          convertPythonExpression.withRemoteControlVariables.toUserDescription(
            args.expression.expression,
          );

        return `${includeStepName ? 'Move arm ' : ''}based on expression ${pyExprStr}`;
      }

      return null;
    }

    return null;
  };
}

MoveArmToTargetStep satisfies Step.StepKindInfo;
