// @ts-ignore (no typings)
import * as JSONStream from 'JSONStream';

import type { CartesianPose } from '@sb/geometry';
import type { DeviceCommand } from '@sb/integrations/types';
import { makeNamespacedLog } from '@sb/log';
import type {
  ArmJointAccelerations,
  ArmJointPositions,
  ArmJointVelocities,
  FrameOfReference,
  JerkLimit,
  JointNumber,
  MotionKind,
  TCPOffsetOption,
} from '@sb/motion-planning';
import type { CommandResult } from '@sb/routine-runner';
import { EventEmitter } from '@sb/utilities';

import type { Globals } from '../Globals';
import type { Routine } from '../Routine';
import type { RoutineRunnerState, RoutineRunning } from '../RoutineRunnerState';
import type { SpeedProfile } from '../speed-profile';
import type { StepValidationMessage } from '../Step';
import type {
  ActuateDeviceArgs,
  IOLevel,
  PayloadState,
  PlayRoutineArgs,
  AnyOutputPortID,
  AnyInputPortID,
  FullAntigravityConfigs,
} from '../types';
import type {
  RunVisionMethodArgs,
  RunVisionMethodResult,
} from '../vision/VisionMethodRunnerTypes';

import type { SenderMessage } from './messages';
import { StatePatchClient } from './state-patch';

const log = makeNamespacedLog('RoutineRunnerPacketSender');

interface PacketEvents {
  // packets that should/will get sent to the receiver
  send: string;

  // packets that come from the receiver
  receive: string;
}

interface MessageEvents {
  // messages that should/will get sent to the receiver
  send(id: number, message: Record<string, any>): void;

  // responses that come from the receiver in response to a
  // sent message
  response(id: number, data: Record<string, any>): void;

  // the receiver sends [[RoutineRunnerState]] on an interval
  state(state: RoutineRunnerState): void;
}

interface LifecycleEvents {
  destroyed: void;

  error: Error;

  // emitted when packets start being handled
  startPacketHandling: void;
  // emitted when packets stop being handled
  stopPacketHandling: void;
}

export class RoutineRunnerPacketSender {
  protected packets = new EventEmitter<PacketEvents>();

  protected messages = new EventEmitter<MessageEvents>();

  protected lifecycle = new EventEmitter<LifecycleEvents>();

  protected isDestroyed = false;

  private statePatchClient = new StatePatchClient<RoutineRunnerState>();

  /**
   * The next message ID to use.
   * Increment by 2 whenever sending a new one.
   */
  private nextMessageID = 0;

  /**
   * Clean up functions to call when the sender gets destroyed
   */
  protected destructors: Array<() => void> = [];

  /**
   * A buffer of packets so we can start making requests using the sender
   * before the network is actually open.
   *
   * Once `.onPacket(cb)` is called, this buffer is flushed.
   */
  private beforeOpenBuffer: Array<string> = [];

  private async bootstrapStateFromSnapshot() {
    try {
      const stateSnapshot = await this.requestResponse({
        kind: 'getStateSnapshot',
      });

      this.statePatchClient.applySnapshot(stateSnapshot);

      if (this.statePatchClient.state) {
        this.messages.emit('state', this.statePatchClient.state);
      }
    } catch (e) {
      log.error(`sync.bootstrapStateFromSnapshot`, 'Failed to bootstrap', e);
    }
  }

  public constructor() {
    const decoder = JSONStream.parse();

    // when a new packet comes in, write it to the decoder for parsing
    this.destructors.push(
      this.packets.on('receive', (packet) => {
        decoder.write(packet);
      }),
    );

    const interval = setInterval(() => {
      if (!this.getState()) {
        this.bootstrapStateFromSnapshot();
      }
    }, 50);

    this.bootstrapStateFromSnapshot();

    this.destructors.push(() => clearInterval(interval));

    const onJSONData = (data: any) => {
      try {
        if (!data || typeof data !== 'object') {
          this.lifecycle.emit(
            'error',
            new TypeError(`Received non-object: ${typeof data}`),
          );

          return;
        }

        if (!('id' in data) || !('data' in data)) {
          this.lifecycle.emit(
            'error',
            new TypeError(
              `Received bad data from receiver: ${JSON.stringify(data)}`,
            ),
          );

          return;
        }

        // message should be of type ReceiverMessage
        const message = data.data;

        if (!('kind' in message)) {
          this.lifecycle.emit(
            'error',
            new TypeError(
              `Received bad data from receiver: ${JSON.stringify(data)}`,
            ),
          );

          return;
        }

        const { kind } = message;

        switch (kind) {
          case 'destroyed': {
            this.destroyHandle();
            break;
          }

          case 'response': {
            this.messages.emit('response', data.id, message);
            break;
          }

          case 'statepatch': {
            const { patch } = message;
            this.statePatchClient.applyPatch(patch);

            const { state } = this.statePatchClient;

            if (state && this.statePatchClient.hash === patch.hash) {
              this.messages.emit('state', state);
            } else {
              log.warn(`sync.outOfSync`, 'State patch client is out of sync', {
                remoteHash: patch.hash,
                localHash: this.statePatchClient.hash,
              });

              // If the state is null, it means that the state patch client
              // has not been initialized yet. This is likely because the
              // state patch client is being initialized for the first time
              // or the client has fallen out of sync
              this.bootstrapStateFromSnapshot();
            }

            break;
          }
          default: {
            this.lifecycle.emit(
              'error',
              new Error(`Received unknown message kind ${kind}`),
            );

            break;
          }
        }
      } catch (e) {
        this.lifecycle.emit('error', e);
      }
    };

    decoder.on('data', onJSONData);

    this.destructors.push(() => decoder.off('data', onJSONData));

    // when sending messages, either buffer it or emit it to
    // `onPacket` listeners.
    this.destructors.push(
      this.messages.on('send', (id, data) => {
        const chunk = JSON.stringify({
          id,
          data,
        });

        if (!this.arePacketsHandled()) {
          this.beforeOpenBuffer.push(chunk);
        } else {
          this.packets.emit('send', chunk);
        }
      }),
    );
  }

  /**
   * Listen for packets that should get sent to the receiver.
   *
   * Only call this once packets are ready to be sent to the other side.
   * Once this is called, packets that would previously have been sent get flushed
   * to the callback and no longer get buffered.
   *
   * @return a cancelation function
   */
  public onPacket(cb: (packet: string) => void): () => void {
    const cancel = this.packets.on('send', cb);

    if (this.packets.listenerCount('send') === 1) {
      this.lifecycle.emit('startPacketHandling');
    }

    this.beforeOpenBuffer.forEach((packet) => {
      cb(packet);
    });

    this.beforeOpenBuffer = [];

    return () => {
      cancel();

      if (!this.arePacketsHandled()) {
        this.lifecycle.emit('stopPacketHandling');
      }
    };
  }

  /**
   * Take in a packet from the receiver.
   */
  public receivePacket(packet: string) {
    this.packets.emit('receive', packet);
  }

  /**
   * Is there an active `onPacket` handler?
   */
  public arePacketsHandled(): boolean {
    // This is an implementation detail, but `onPacket` should be called once
    // a connection has been established while the cancelation function should be
    // called once the connection closes, so the listener count is indicative
    // of whether the connection is open.
    return this.packets.listenerCount('send') !== 0;
  }

  /**
   * Drop connection without killing the RoutineRunner
   */
  public destroyHandle(): void {
    if (this.isDestroyed) {
      return;
    }

    this.isDestroyed = true;

    this.lifecycle.emit('destroyed');
    this.packets.removeAllListeners();
    this.lifecycle.removeAllListeners();
    this.messages.removeAllListeners();

    this.destructors.forEach((destructor) => destructor());
  }

  /**
   * Sends a message and wait for the response.
   *
   * Can be used by subclasses to request extensions.
   *
   * @throws if the response includes an error.
   * @returns A promise with the `responseData` field of the `ReceiverMessage`
   */
  protected async requestResponse(request: SenderMessage): Promise<any> {
    if (this.isDestroyed) {
      throw new Error(
        `Tried to send ${request.kind} message when RoutineRunnerPacketSender was destroyed`,
      );
    }

    const error = new Error();

    const id = this.nextMessageID;

    const nextMessagePromise = this.messages.nextAll(
      'response',
      (receivedID) => id === receivedID,
    );

    this.messages.emit('send', id, request);
    this.nextMessageID += 2;

    const [, message] = await nextMessagePromise;

    if ('error' in message) {
      error.message = message.error;
      throw error;
    }

    return message.responseData;
  }

  /**
   * Handler for when the Sender gets destroyed from either side.
   */
  public onDestroy(cb: () => void): () => void {
    return this.lifecycle.on('destroyed', cb);
  }

  /**
   * Call [[RoutineRunner.loadRoutine]] on the remote Routine Runner
   */
  public loadRoutine(
    routine: Routine,
    globals: Globals,
    startConditions?: Pick<RoutineRunning, 'currentStepID' | 'variables'>,
  ): Promise<{ errors: Array<StepValidationMessage> }> {
    return this.requestResponse({
      kind: 'loadRoutine',
      routine,
      globals,
      startConditions,
    });
  }

  /**
   * Call [[RoutineRunner.moveToJointSpacePoint]] on the remote Routine Runner
   */
  public moveToJointSpacePoint(
    goal: ArmJointPositions,
    options: SpeedProfile,
  ): Promise<CommandResult> {
    return this.requestResponse({
      kind: 'moveToJointSpacePoint',
      goal,
      options,
    });
  }

  /**
   * Call [[RoutineRunner.moveToBoxingPosition]] on the remote Routine Runner
   */
  public moveToBoxingPosition(
    goal: ArmJointPositions,
    options: SpeedProfile,
  ): Promise<CommandResult> {
    return this.requestResponse({
      kind: 'moveToBoxingPosition',
      goal,
      options,
    });
  }

  public getJointAnglesForCartesianSpacePose(
    goal: CartesianPose,
    motionKind: MotionKind,
    isJogging?: boolean,
  ): Promise<ArmJointPositions | null> {
    return this.requestResponse({
      kind: 'getJointAnglesForCartesianSpacePose',
      goal,
      motionKind,
      isJogging,
    });
  }

  /**
   * Call [[RoutineRunner.moveToCartesianSpacePose] on the remote Routine Runner
   */
  public moveToCartesianSpacePose(
    goal: CartesianPose,
    motionKind: MotionKind,
    options: SpeedProfile,
  ): Promise<CommandResult> {
    return this.requestResponse({
      kind: 'moveToCartesianSpacePose',
      goal,
      motionKind,
      options,
    });
  }

  /**
   * Call [[RoutineRunner.moveJointRelative]] on the remote Routine Runner
   */
  public moveJointRelative(
    jointNumber: JointNumber,
    options: SpeedProfile,
  ): Promise<void> {
    return this.requestResponse({
      kind: 'moveJointRelative',
      jointNumber,
      options,
    });
  }

  /**
   * Call [[RoutineRunner.moveToolRelative]] on the remote Routine Runner
   */
  public moveToolRelative(
    frame: FrameOfReference,
    offset: CartesianPose,
    options: SpeedProfile,
  ): Promise<void> {
    return this.requestResponse({
      kind: 'moveToolRelative',
      frame,
      offset,
      options,
    });
  }

  public executeDeviceCommand(command: DeviceCommand): Promise<void> {
    return this.requestResponse({
      kind: 'executeDeviceCommand',
      command,
    });
  }

  /**
   * Call [[RoutineRunner.actuateDevice]] on the remote Routine Runner
   */
  public actuateDevice(args: ActuateDeviceArgs): Promise<CommandResult> {
    return this.requestResponse({
      kind: 'actuateDevice',
      ...args,
    });
  }

  /**
   * Call [[RoutineRunner.setOutputIO]] on the remote Routine Runner
   */
  public setOutputIO(
    changes: Array<{ label: AnyOutputPortID; level: IOLevel }>,
  ): Promise<void> {
    return this.requestResponse({
      kind: 'setOutputIO',
      changes,
    });
  }

  /**
   * Call [[RoutineRunner.recover]] on the remote Routine Runner
   */
  public recover(
    targetJointAngles?: ArmJointPositions,
  ): Promise<CommandResult> {
    return this.requestResponse({
      kind: 'recover',
      targetJointAngles,
    });
  }

  /**
   * Call [[RoutineRunner.playRoutine]] on the remote Routine Runner
   */
  public playRoutine(args: PlayRoutineArgs): Promise<void> {
    return this.requestResponse({
      kind: 'playRoutine',
      ...args,
    });
  }

  /**
   * Call [[RoutineRunner.skipPreflightTestRun]] on the remote Routine Runner
   */
  public skipPreflightTestRun(): Promise<void> {
    return this.requestResponse({ kind: 'skipPreflightTestRun' });
  }

  /**
   * Call [[RoutineRunner.changeRoutineSpeedProfile]] on the remote Routine Runner
   */
  public changeRoutineSpeedProfile(speedProfile: SpeedProfile): Promise<void> {
    return this.requestResponse({
      kind: 'changeRoutineSpeedProfile',
      speedProfile,
    });
  }

  public setSpeedRestrictionPercentage(
    speedRestrictionPercentage: number,
  ): Promise<void> {
    return this.requestResponse({
      kind: 'setSpeedRestrictionPercentage',
      speedRestrictionPercentage,
    });
  }

  public setTestRunSpeed(speedProfile: SpeedProfile): Promise<void> {
    return this.requestResponse({
      kind: 'setTestRunSpeed',
      speedProfile,
    });
  }

  /**
   * Call [[RoutineRunner.setAntigravityConfigs]] on the remote Routine Runner
   */
  public setAntigravityConfigs(configs: {
    [key: string]: any;
  }): Promise<FullAntigravityConfigs> {
    return this.requestResponse({
      kind: 'setAntigravityConfigs',
      configs,
    });
  }

  /**
   * Call [[RoutineRunner.setAntigravityConfigs]] on the remote Routine Runner
   */
  public getAntigravityConfigs(): Promise<FullAntigravityConfigs> {
    return this.requestResponse({
      kind: 'getAntigravityConfigs',
    });
  }

  /**
   * Call [[RoutineRunner.stop]] on the remote Routine Runner
   */
  public stop(reasonForStopping: string): Promise<void> {
    return this.requestResponse({
      kind: 'stop',
      reasonForStopping,
    });
  }

  /**
   * Call [[RoutineRunner.pauseRoutine]] on the remote Routine Runner
   */
  public pauseRoutine(): Promise<void> {
    return this.requestResponse({
      kind: 'pauseRoutine',
    });
  }

  /**
   * Call [[RoutineRunner.emergencyStop]] on the remote Routine Runner
   */
  public emergencyStop(source: string): Promise<void> {
    return this.requestResponse({
      kind: 'emergencyStop',
      source,
    });
  }

  /**
   * Call [[RoutineRunner.resumeRoutine]] on the remote Routine Runner
   */
  public resumeRoutine(): Promise<void> {
    return this.requestResponse({
      kind: 'resumeRoutine',
    });
  }

  public confirmStep(stepID: string, input?: string): Promise<boolean> {
    return this.requestResponse({
      kind: 'confirmStep',
      stepID,
      input,
    });
  }

  /**
   * Synchronous retrieval of state.
   *
   * Returns the last value that came through over the connection.
   */
  public getState(): RoutineRunnerState | null {
    return this.statePatchClient.state ?? null;
  }

  /**
   * Call [[RoutineRunner.anticipatePayload]] on the remote Routine Runner
   */
  public setRobotPayload(payload: PayloadState) {
    return this.requestResponse({
      kind: 'setRobotPayload',
      payload,
    });
  }

  /**
   * Call [[RoutineRunner.getGlobalSpeedLimits]] on the remote Routine Runner
   */
  public getGlobalSpeedLimits(): Promise<{
    maxJointVelocities: ArmJointVelocities;
    maxJointAccelerations: ArmJointAccelerations;
    maxTooltipSpeed: number;
  }> {
    return this.requestResponse({
      kind: 'getGlobalSpeedLimits',
    });
  }

  /**
   * Call [[RoutineRunner.validateGuidedMode]] on the remote Routine Runner
   */
  public validateGuidedMode(): Promise<void> {
    return this.requestResponse({
      kind: 'validateGuidedMode',
    });
  }

  public setJerkLimit(jerkLimit: JerkLimit): Promise<void> {
    return this.requestResponse({
      kind: 'setJerkLimit',
      jerkLimit,
    });
  }

  public runVisionMethod(
    args: RunVisionMethodArgs,
  ): Promise<RunVisionMethodResult> {
    return this.requestResponse({ kind: 'runVisionMethod', ...args });
  }

  public setTCPOffsetOption(tcpOffsetOption: TCPOffsetOption): Promise<void> {
    return this.requestResponse({
      kind: 'setTCPOffsetOption',
      tcpOffsetOption,
    });
  }

  public vizbotOnlySetJointPositions(
    positions: ArmJointPositions,
  ): Promise<void> {
    return this.requestResponse({
      kind: 'vizbotOnlySetJointPositions',
      positions,
    });
  }

  public vizbotOnlySetInputIO(
    label: AnyInputPortID,
    level: IOLevel,
  ): Promise<void> {
    return this.requestResponse({
      kind: 'vizbotOnlySetInputIO',
      label,
      level,
    });
  }

  public unbrakeJoint(jointNumber: JointNumber): Promise<void> {
    return this.requestResponse({
      kind: 'unbrakeJoint',
      jointNumber,
    });
  }

  public clearMotionPlanCache(): Promise<void> {
    return this.requestResponse({
      kind: 'clearMotionPlanCache',
    });
  }

  public setROSControlEnabled(enabled: boolean): Promise<void> {
    return this.requestResponse({
      kind: 'setROSControlEnabled',
      enabled,
    });
  }

  public setInFailureState(
    isInFailureState: boolean,
    isRecoverableWithWristButton: boolean,
  ): Promise<void> {
    return this.requestResponse({
      kind: 'setInFailureState',
      isInFailureState,
      isRecoverableWithWristButton,
    });
  }
}
