import * as zod from 'zod';

export const AntigravitySettings = zod.object({
  maxSpeed: zod.number(),
  maxAcceleration: zod.number(),
  disturbanceThreshold: zod.number(),
  accelerationPerDisturbance: zod.number(),
  maxTorqueThreshold: zod.number(),
  lpfAlpha: zod.number(),
  useAmperageDisturbanceToBegin: zod.boolean().default(true),
  useAmperageDisturbanceWhenMoving: zod.boolean().default(true),
});

export type AntigravitySettings = zod.infer<typeof AntigravitySettings>;

export const AntigravityModeSettings = zod.object({
  regular: AntigravitySettings,
  fineTune: AntigravitySettings,
});

export type AntigravityModeSettings = zod.infer<typeof AntigravityModeSettings>;

export const AntigravityConfigurations = zod.object({
  ro1: AntigravityModeSettings,
  ro2: AntigravityModeSettings,
});

export type AntigravityConfigurations = zod.infer<
  typeof AntigravityConfigurations
>;

export const FullAntigravityConfigs = zod.array(AntigravityConfigurations);

export type FullAntigravityConfigs = zod.infer<typeof FullAntigravityConfigs>;
