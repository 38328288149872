import type * as zod from 'zod';

import {
  readCodeBlocksOutputOnDisk,
  saveCodeBlocksOnDisk,
} from '@sb/local-build-data';
import { convertPythonExpression } from '@sb/remote-control/util/expressions/v2';
import { FailureKind } from '@sb/routine-runner';

import { createRoutineVariablesMap } from '../../util';
import Step, { type StepPlayArguments } from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class CodeBlockStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  protected initializeVariableState(): void {
    this.variables = { stdout: '', stdoutTimestamp: undefined };
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    if (!this.routineContext.loadedRoutineState) {
      return fail({
        failure: {
          kind: FailureKind.StepPlayFailure,
          stepKind: 'CodeBlock',
        },
        failureReason: 'Routine State not found',
      });
    }

    // Convert the code back to user-readable from ID form.
    // This helps with stacktraces
    const idNameMap = createRoutineVariablesMap.getIdNameMap(
      this.routineContext.loadedRoutineState,
    );

    const { code } = convertPythonExpression.withIdNameMap.toUser(
      this.args.codeBlockCode,
      idNameMap,
    );

    try {
      // create code block in local folder
      await saveCodeBlocksOnDisk(this.args.codeBlockId, code);
    } catch (err) {
      return fail({
        failure: {
          kind: FailureKind.StepPlayFailure,
          stepKind: 'CodeBlock',
        },
        error: err,
        failureReason: 'Could not access CodeBlock folder',
      });
    }

    try {
      await this.routineContext.runHostCommand({
        command: 'codeBlocks',
        codeBlockPath: this.args.codeBlockId,
        routineId: this.routineContext.loadedRoutineState.id,
        robotToken: this.routineContext.robotToken,
        contextKind: 'code-block-step',
        robotKind: this.routineContext.robot.robotKind,
      });
    } catch (err) {
      const stderr = await readCodeBlocksOutputOnDisk(
        this.args.codeBlockId,
        'stderr',
      );

      return fail({
        failure: {
          kind: FailureKind.StepPlayFailure,
          stepKind: 'CodeBlock',
          stackTrace: stderr !== '' ? stderr : undefined,
        },
        error: err,
        failureReason: 'An error occurred while running the CodeBlock',
      });
    }

    // For now, not limiting the character output (e.g. 2000 characters). This could turn out to be an issue down the road.
    const stdout = await readCodeBlocksOutputOnDisk(
      this.args.codeBlockId,
      'stdout',
    );

    this.setVariable('stdout', stdout);
    this.setVariable('stdoutTimestamp', new Date());
  }
}
