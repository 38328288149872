import cx from 'classnames';
import { forwardRef } from 'react';

import type { IconKind } from '../../../icons';
import { Icon } from '../../../icons';
import type { ComponentSize } from '../../../utility';
import { INPUT_SIZE_DEFAULT, INPUT_SIZE_CLASSES } from '../inputSize';
import type { InputVariant } from '../inputVariant';
import { INPUT_VARIANT_CLASSES, INPUT_VARIANT_DEFAULT } from '../inputVariant';

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  size?: ComponentSize;
  variant?: InputVariant;
  icon?: IconKind;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      className,
      size = INPUT_SIZE_DEFAULT,
      variant = INPUT_VARIANT_DEFAULT,
      icon = 'chevronUpDown',
      ...rest
    },
    ref,
  ) => {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={cx(
          className,
          'tw-inline-grid',
          INPUT_SIZE_CLASSES[size],
          INPUT_VARIANT_CLASSES[variant],
          'tw-p-0',
          'tw-text-label-primary',
        )}
      >
        <select
          className={cx(
            'tw-peer',
            'tw-appearance-none',
            'tw-bg-transparent',
            variant !== 'Flat' && 'tw-pl-16',
            variant === 'Flat' ? 'tw-pr-24' : 'tw-pr-40',
            'tw-h-full',
            'tw-w-full',
            'tw-text-ellipsis',
            'placeholder:tw-text-label-quaternary',
            'disabled:tw-text-label-quaternary',
            /* override browser default */
            'disabled:tw-opacity-100',
            'tw-col-start-1',
            'tw-row-start-1',
          )}
          {...rest}
          ref={ref}
        />

        <Icon
          kind={icon}
          className={cx(
            'tw-pointer-events-none',
            'tw-text-label-secondary',
            'tw-col-start-1',
            'tw-row-start-1',
            'tw-justify-self-end',
            'tw-self-center',
            variant !== 'Flat' && 'tw-mr-12',
            'peer-disabled:tw-text-label-quaternary',
          )}
        />
      </div>
    );
  },
);
