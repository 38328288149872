import * as zod from 'zod';

import { WeldMachineMode } from '../../shared/types/WeldMachineMode';

export const MillerWeldMachineState = zod.object({
  kind: zod.literal('MillerWeldMachine'),
  isReady: zod.boolean(),
  voltage: zod.number(),
  current: zod.number(),
  wireFeedSpeed: zod.number(),
  mode: WeldMachineMode,
  isTestMode: zod.boolean(),
});

export type MillerWeldMachineState = zod.infer<typeof MillerWeldMachineState>;
