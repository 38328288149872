import * as zod from 'zod';

import {
  OutputPortID,
  IOLevel,
  RelayPortID,
  FlangeOutputPortID,
} from '../../types';

const PortID = zod.enum([
  ...OutputPortID.options,
  ...RelayPortID.options,
  ...FlangeOutputPortID.options,
]);

export default zod.object({
  changes: zod.array(
    zod.object({
      label: PortID,
      level: IOLevel,
    }),
  ),
});
