import * as zod from 'zod';

import { RoutineRunnerEnvironmentVariable } from '@sb/routine-runner/types/Variable';

export const GlobalVariableData = zod.object({
  id: zod.string(),
  globalVariableItem: RoutineRunnerEnvironmentVariable.Item,
  created: zod.string(),
  updated: zod.string(),
});
export type GlobalVariableData = zod.infer<typeof GlobalVariableData>;
