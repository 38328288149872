import * as zod from 'zod';

import { OR_DUAL_QUCK_CHANGER_SHORTNAME } from '../constants';

import { OnRobotDualQuickChangerCompatibleGripper } from './OnRobotDualQuickChangerCompatibleGripper';

export const OnRobotDualQuickChangerConfiguration = zod.object({
  kind: zod.literal('OnRobotDualQuickChanger'),
  name: zod.string().default(OR_DUAL_QUCK_CHANGER_SHORTNAME),
  grippers: zod.object({
    primary: OnRobotDualQuickChangerCompatibleGripper,
    secondary: OnRobotDualQuickChangerCompatibleGripper,
  }),
  mountOrientation: zod.number().default(0),
});

export type OnRobotDualQuickChangerConfiguration = zod.infer<
  typeof OnRobotDualQuickChangerConfiguration
>;
