import * as zod from 'zod';

import { Expression } from '../../types';

export default zod.object({
  variableName: zod.string(),
  variableID: zod.string(),
  newValue: Expression,
  isGlobal: zod.boolean().optional().default(false),
});
