import * as zod from 'zod';

import { ActionRequiredError, Step } from '@sb/remote-control/types';

export namespace RunSkillStep {
  export const name = 'Run learned skill';
  export const description = 'Run an AI learned skill';
  export const librarySection = Step.LibrarySection.Control;
  export const argumentKind = 'RunSkill';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    inferenceMachineEndpoint: zod.string().nullable().default(null),
    modelPath: zod.string().nullable().default(null),
    timeout: zod.number().default(0),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (!args.modelPath) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Model path not specified',
        fieldId: 'modelPath',
      });
    }

    if (!args.inferenceMachineEndpoint) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'No inference machine specified',
        fieldId: 'inferenceMachineEndpoint',
      });
    }

    return {
      ...stepData,
      stepKind: 'RunSkill',
      args: {
        modelPath: args.modelPath,
        inferenceMachineEndpoint: args.inferenceMachineEndpoint,
        timeout: args.timeout,
      },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
  }) => {
    if (args?.argumentKind !== argumentKind) {
      return null;
    }

    return `Run skill with model “${args.modelPath}”`;
  };
}

RunSkillStep satisfies Step.StepKindInfo;
