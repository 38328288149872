import * as zod from 'zod';

import { Step } from '@sb/remote-control/types';
import { buildConditional } from '@sb/remote-control/util/conditionalsBuilder';
import { roundToDecimalPlaces } from '@sb/utilities';

import { validateConditional } from '../util/conditionalsValidator';

export namespace WaitStep {
  export const name = 'Wait';
  export const description =
    'Wait for a condition such as time, IO input, or an event';
  export const librarySection = Step.LibrarySection.Control;
  export const librarySort = '2';
  export const argumentKind = 'Wait';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    milliseconds: zod.number().nullable(),
    /**
     * Condition: will override `milliseconds` if non-null
     * The actual type is ConditionalBuilderState[][] but Firestore doesn't support nested
     * arrays, so we need to serialize/desarialize this property when using it.
     */
    condition: zod.string().nullable(),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export function isMillisecondsArguments(
    args: Arguments,
  ): args is Arguments & { milliseconds: number } {
    return typeof args.milliseconds === 'number';
  }

  export function isConditionArguments(
    args: Arguments,
  ): args is Arguments & { condition: string } {
    return typeof args.condition === 'string';
  }

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (isConditionArguments(args)) {
      return {
        ...stepData,
        stepKind: 'Wait',
        args: { condition: buildConditional(JSON.parse(args.condition)) },
      };
    }

    if (isMillisecondsArguments(args)) {
      return {
        ...stepData,
        stepKind: 'Wait',
        args: { milliseconds: args.milliseconds },
      };
    }

    throw new Error(
      'Either milliseconds or condition must be provided to a Wait step',
    );
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
    includeStepName,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      return null;
    }

    if (args.milliseconds) {
      const timeS = roundToDecimalPlaces(args.milliseconds / 1_000, 2);

      return [
        includeStepName ? `Wait` : false,
        'for',
        timeS === 1 ? 'a second' : `${timeS} seconds`,
      ]
        .filter((line) => line !== false)
        .join(' ');
    }

    // Todo print expression
    return null;
  };

  export const validator: Step.Validator = ({ stepConfiguration, routine }) => {
    const args = (stepConfiguration?.args || {}) as Arguments;

    if (isConditionArguments(args)) {
      const conditions = JSON.parse(args.condition || '[]');

      validateConditional(conditions, routine);
    }
  };
}

WaitStep satisfies Step.StepKindInfo;
