export class ActionCancelledError extends Error {
  public constructor(message: string) {
    super(message);

    // Set the name to the name of the custom error class
    this.name = this.constructor.name;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    // Explicitly set the prototype to maintain the prototype chain
    Object.setPrototypeOf(this, ActionCancelledError.prototype);
  }
}
