import * as zod from 'zod';

export const CustomIOPort = zod.object({
  kind: zod.union([
    zod.literal('Input'),
    zod.literal('Output'),
    zod.literal('Relay'),
  ]),
  name: zod.string(),
  port: zod.number(),
  lowSignalName: zod.string(),
  highSignalName: zod.string(),
});

export type CustomIOPort = zod.infer<typeof CustomIOPort>;

export const CustomIOConfiguration = zod.object({
  kind: zod.literal('CustomIO'),
  name: zod.string().default('IO Device'),
  ports: zod.array(CustomIOPort).default([]),
  customIcon: zod.string().nullable().default(null),
});

export type CustomIOConfiguration = zod.infer<typeof CustomIOConfiguration>;
