import { LRUCache } from 'lru-cache';
// @ts-ignore
import type * as RCL from 'rclnodejs';

import type { CartesianPose } from '@sb/geometry';
import { targetToROS2Tooltip, ros2TooltipToTarget } from '@sb/geometry';
import { makeNamespacedLog } from '@sb/log';
import { ArmJointPositions } from '@sb/motion-planning';
import type {
  InverseKinematicsSolution,
  InverseKinematicsRequest,
  ForwardKinematicsResponse,
  ForwardKinematicsRequest,
} from '@sb/motion-planning';
import { TaskFromRobotGoalAction } from '@sb/ros/TaskFromRobotGoalAction';
import type { TaskFromRobotGoalActionRequest } from '@sb/ros/TaskFromRobotGoalAction';
import { TaskFromRobotGoalActionResult } from '@sb/ros/types/TaskFromRobotGoalActionResult';
import type { CacheBackend } from '@sb/utilities/src/ReadThroughCache';
import { ReadThroughCacheAsync } from '@sb/utilities/src/ReadThroughCacheAsync';

import { FKCheckService } from './FKCheckService';
import { getRosMotionPlanCacheKey } from './getRosMotionPlanCacheyKey';
import { IKCollisionCheckService } from './IKCollisionCheckService';
import { JoggingAction, type JoggingActionRequest } from './JoggingAction';

const log = makeNamespacedLog('WebSocketMotionPlanner');

export type RosMotionPlannerRequest = TaskFromRobotGoalActionRequest;
export class RosMotionPlanner {
  private motionPlanCache: ReadThroughCacheAsync<
    RosMotionPlannerRequest,
    TaskFromRobotGoalActionResult
  > | null = null;

  public constructor(
    motionPlanCacheBackend: CacheBackend<TaskFromRobotGoalActionResult> | null = null,
  ) {
    if (motionPlanCacheBackend) {
      this.motionPlanCache = new ReadThroughCacheAsync<
        RosMotionPlannerRequest,
        TaskFromRobotGoalActionResult
      >({
        getValue: async (request) => {
          const planAction = new TaskFromRobotGoalAction(request);
          const planResult = await planAction.call();

          return TaskFromRobotGoalActionResult.parse(planResult);
        },
        getCacheKey: (req) => getRosMotionPlanCacheKey(req),
        cacheBackend: motionPlanCacheBackend,
      });
    }
  }

  private forwardKinematicsCache = new LRUCache<
    string,
    ForwardKinematicsResponse
  >({ max: 50 });

  public async forwardKinematics(
    request: ForwardKinematicsRequest,
  ): Promise<ForwardKinematicsResponse> {
    const key = `
      ${request.jointAngles.map((angle) => `${angle.toFixed(4)}`).join('|')}
      ${request.checkValidity}
      ${request.gripperOpenness.toFixed(2)}
    `;

    const cachedKinematics = this.forwardKinematicsCache.get(key);

    if (cachedKinematics) {
      return cachedKinematics;
    }

    const jointState = {
      header: {
        stamp: { sec: 0, nanosec: 0 },
        frame_id: 'world',
      },
      name: ['joint0', 'joint1', 'joint2', 'joint3', 'joint4', 'joint5'],
      position: request.jointAngles,
      velocity: [],
      effort: [],
    };

    const fk_service = new FKCheckService(jointState, request.checkValidity);

    const error = new Error();

    try {
      const response = await fk_service.call();

      if (!response.success) {
        throw new Error('ROS2 Forward kinematics failed');
      }

      const pose: CartesianPose = ros2TooltipToTarget({
        x: response.pose.position.x,
        y: response.pose.position.y,
        z: response.pose.position.z,
        i: response.pose.orientation.x,
        j: response.pose.orientation.y,
        k: response.pose.orientation.z,
        w: response.pose.orientation.w,
      });

      const collisions = response.collisions.map(
        (collision: RCL.standard_bots_msgs.msg.Collision) => ({
          elementNames: [collision.body_1, collision.body_2].sort() as [
            string,
            string,
          ],
        }),
      );

      const result: ForwardKinematicsResponse = {
        pose,
        collisions,
        isColliding: response.in_collision,
      };

      this.forwardKinematicsCache.set(key, result);

      return result;
    } catch (e) {
      error.message = `Forward kinematics failed: ${e.message}`;
      throw error;
    }
  }

  public async inverseKinematics(
    request: InverseKinematicsRequest,
  ): Promise<Array<InverseKinematicsSolution>> {
    const error = new Error();
    const tooltipPose = targetToROS2Tooltip(request.pose);

    const goal_pose: RCL.geometry_msgs.msg.Pose = {
      position: {
        x: tooltipPose.x,
        y: tooltipPose.y,
        z: tooltipPose.z,
      },
      orientation: {
        x: tooltipPose.i,
        y: tooltipPose.j,
        z: tooltipPose.k,
        w: tooltipPose.w,
      },
    };

    const ik_service = new IKCollisionCheckService(
      goal_pose,
      request.checkValidity,
    );

    const response = await ik_service.call();

    if (!response.success) {
      error.message = 'Inverse kinematics failed';
      throw error;
    }

    const jointPositions = Array.from(response.ik_solution.position).slice(
      0,
      6,
    );

    // Validate and parse the joint positions
    let jointAngles: ArmJointPositions;

    try {
      jointAngles = ArmJointPositions.parse(jointPositions);
    } catch (e) {
      error.message = `Failed to parse joint positions: ${e.message}`;
      throw error;
    }

    const solution: InverseKinematicsSolution = {
      jointAngles,
      collisions: [], // IKCollisionCheck_Response doesn't provide detailed collision information
      isColliding: response.in_collision,
      violatesLimits: false, // This information is not provided in IKCollisionCheck_Response
      limitViolations: new Array(6).fill(null), // Assuming 6 joints, fill with null as this info is not provided
    };

    return [solution];
  }

  public async getMotionPlan(
    request: RosMotionPlannerRequest,
  ): Promise<TaskFromRobotGoalActionResult> {
    if (!this.motionPlanCache) {
      throw new Error('Motion plan cache not initialized');
    }

    const response = this.motionPlanCache.get(request);

    log.info(`RosMotionPlanner.getMotionPlan`, 'planMotion cache stats', {
      hitCount: this.motionPlanCache.hitCount,
      getCount: this.motionPlanCache.getCount,
      hitRate: (
        this.motionPlanCache.hitCount / this.motionPlanCache.getCount
      ).toPrecision(4),
      cacheSize: this.motionPlanCache.size(),
    });

    return response;
  }

  public clearMotionPlanCache() {
    if (this.motionPlanCache) {
      this.motionPlanCache.reset();
    }
  }

  public async planRelativeJointMotion(
    jogActionRequest: JoggingActionRequest,
  ): Promise<RCL.standard_bots_msgs.action.Jogging_Result> {
    const jogAction = new JoggingAction(jogActionRequest);

    return await jogAction.call();
  }

  public async planRelativeCartesianMotion(
    jogActionRequest: JoggingActionRequest,
  ): Promise<RCL.standard_bots_msgs.action.Jogging_Result> {
    const jogAction = new JoggingAction(jogActionRequest);

    return await jogAction.call();
  }
}
