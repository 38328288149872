import { sumBy } from 'lodash';

import {
  applyCompoundPose,
  invertPose,
  tooltipCoordinatesToBaseCoordinates,
  type CartesianPose,
} from '@sb/geometry';
import { makeLogNamespace } from '@sb/log/log';
import {
  cartesianProduct,
  isApproximatelyEqual,
  isNotNull,
  isNotUndefined,
  timeout,
} from '@sb/utilities';

import type { ArmJointLimits } from './ArmJointLimits';
import type { ArmJointPositions } from './ArmJointPositions';
import type { ArmTarget } from './ArmTarget';
import { inverseKinematics } from './inverseKinematics';
import { JOINT_NUMBERS } from './JointNumber';
import type { MotionPlan } from './MotionPlan';
import type {
  DeviceKinematics,
  DeviceOffsetProposal,
  MotionPlannerInterface,
} from './MotionPlannerInterface';
import type { MotionPlanRequest } from './MotionPlanRequest';
import type { TCPOffsetOption } from './TCPOffsetOption';

interface ConstructorArgs<DeviceCommand> {
  hybridMotionPlanner: MotionPlannerInterface;
  motionPlanner: MotionPlannerInterface;
  request: MotionPlanRequest;
  deviceKinematics: DeviceKinematics<DeviceCommand>[];
  onAcknowledged?: () => void;
  onWaypoint?: () => void;
  tcpOffsetOption: TCPOffsetOption;
  jointLimits: ArmJointLimits;
  isJogging?: boolean;
  moveDynamicBaseToReachPosition?: boolean;
}

const log = makeLogNamespace('ArmAndDeviceMotionPlanner');

export interface ArmAndDeviceMotionPlan<DeviceCommand> {
  deviceCommands: DeviceCommand[];
  motionPlan: MotionPlan;
}

/**
 * Motion planner which takes into account devices which can
 * change the position of the TCP (e.g. grippers) or arm (e.g. vertical lift)
 *
 * Each attached device returns a list of possible positions
 * it can move the arm to ("device offset proposals")
 *
 * The proposals are looped through (in order of estimated duration),
 * the motion plan request is modified to take into account the offset,
 * and we find the first offset where the motion is possible.
 *
 * Return the device command(s) which move the arm to position
 * and the motion plan from the modified request.
 */
export class ArmAndDeviceMotionPlanner<DeviceCommand> {
  private hybridMotionPlanner: MotionPlannerInterface;

  private motionPlanner: MotionPlannerInterface;

  private request: MotionPlanRequest;

  private deviceKinematics: DeviceKinematics<DeviceCommand>[];

  private onAcknowledged?: () => void;

  private onWaypoint?: () => void;

  private tcpOffsetOption: TCPOffsetOption;

  private jointLimits: ArmJointLimits;

  private moveDynamicBaseToReachPosition: boolean;

  private isJogging: boolean;

  public constructor(props: ConstructorArgs<DeviceCommand>) {
    this.hybridMotionPlanner = props.hybridMotionPlanner;
    this.motionPlanner = props.motionPlanner;
    this.request = props.request;
    this.deviceKinematics = props.deviceKinematics;
    this.onAcknowledged = props.onAcknowledged;
    this.onWaypoint = props.onWaypoint;
    this.tcpOffsetOption = props.tcpOffsetOption;
    this.jointLimits = props.jointLimits;

    this.moveDynamicBaseToReachPosition =
      props.moveDynamicBaseToReachPosition ?? false;

    this.isJogging = props.isJogging ?? false;
  }

  private calculateCombinedDuration(
    proposals: DeviceOffsetProposal<DeviceCommand>[],
  ): number {
    return sumBy(proposals, (p) => p.durationEstimateMS);
  }

  private getDeviceOffsetProposals(): DeviceOffsetProposal<DeviceCommand>[][] {
    const hasPoseTarget = this.request.targets.some(
      (target) => 'pose' in target,
    );

    if (!hasPoseTarget) {
      return [];
    }

    const proposalsByDevice = this.deviceKinematics
      .map((kinematics) => {
        // commands cannot be set to devices when jogging
        if (!this.isJogging && kinematics.getDeviceOffsetProposals) {
          let proposals = kinematics.getDeviceOffsetProposals();

          if (!this.moveDynamicBaseToReachPosition) {
            proposals = proposals.filter(
              (proposal) => proposal.deviceOffset.kind !== 'base',
            );
          }

          if (proposals.length > 0) {
            return proposals;
          }
        }

        // default proposal is use current device offset
        const deviceOffset = kinematics.getOffset?.({
          tcpOption: this.tcpOffsetOption,
        });

        if (deviceOffset) {
          return [
            {
              deviceOffset,
              command: null,
              durationEstimateMS: 0,
            },
          ];
        }

        return undefined;
      })
      .filter(isNotUndefined);

    const combinedProposals = Array.from(
      cartesianProduct(...proposalsByDevice),
    );

    combinedProposals.sort(
      (p1, p2) =>
        this.calculateCombinedDuration(p1) - this.calculateCombinedDuration(p2),
    );

    return combinedProposals;
  }

  public async plan(): Promise<ArmAndDeviceMotionPlan<DeviceCommand>> {
    const combinedProposals = this.getDeviceOffsetProposals();

    // for each combination of proposals from attached devices,
    // see if we can plan a motion to the target
    if (combinedProposals.length > 0) {
      const errorMessages = new Map<string, number>();

      for (const combinedProposal of combinedProposals) {
        try {
          const deviceCommands = combinedProposal
            .map((p) => p.command)
            .filter(isNotNull);

          log.info('motionPlan.attempt', 'Try to plan motion with', {
            deviceCommands,
          });

          const motionPlan =
            await this.planMotionWithCombinedProposal(combinedProposal);

          return {
            deviceCommands,
            motionPlan,
          };
        } catch (e) {
          errorMessages.set(e.message, (errorMessages.get(e.message) ?? 0) + 1);
        }
      }

      const errorSummary = [...errorMessages]
        .map(([message, count]) => `${count} × ${message}`)
        .join('; ');

      throw new Error(
        `Unable to plan motion from any offset proposals: ${errorSummary}`,
      );
    } else {
      const motionPlan = await this.planMotion(this.request);

      return {
        deviceCommands: [],
        motionPlan,
      };
    }
  }

  // return all the ways to reach the target pose
  public async getProposalsForPose(targetPose: CartesianPose): Promise<
    Array<{
      pose: CartesianPose;
      jointAngles: ArmJointPositions;
      combinedProposal: DeviceOffsetProposal<DeviceCommand>[];
    }>
  > {
    const combinedProposals = this.getDeviceOffsetProposals();

    // include the nil-proposal (e.g. TCP = wrist and no lift attached)
    if (combinedProposals.length === 0) {
      combinedProposals.push([]);
    }

    const poseProposals = await Promise.all(
      combinedProposals.map(async (combinedProposal) => {
        const pose = this.getModifiedPose(targetPose, combinedProposal);

        if (!pose) {
          return null;
        }

        const [mpResult] = await this.hybridMotionPlanner.inverseKinematics({
          jointSeed: this.request.startingJointPositions,
          checkValidity: true,
          gripperOpenness: 1,
          pose,
        });

        if (!mpResult || mpResult.isColliding) {
          return null;
        }

        // when jogging ignore ik results far away in joint space (>45° on any joint)
        if (
          this.isJogging &&
          !JOINT_NUMBERS.every((j) =>
            isApproximatelyEqual(
              this.request.startingJointPositions[j],
              mpResult.jointAngles[j],
              Math.PI / 4,
            ),
          )
        ) {
          return null;
        }

        return { pose, jointAngles: mpResult.jointAngles, combinedProposal };
      }),
    );

    return poseProposals.filter(isNotNull);
  }

  private async planMotion(request: MotionPlanRequest): Promise<MotionPlan> {
    const motionPlanResponse = this.motionPlanner.planMotion(request);

    const timeoutHandle = timeout(5000, 'Motion planner is not responding');

    if (this.onAcknowledged) {
      motionPlanResponse.on('acknowledged', this.onAcknowledged);
      timeoutHandle.cancel();
    }

    if (this.onWaypoint) {
      motionPlanResponse.on('waypoint', this.onWaypoint);
      timeoutHandle.cancel();
    }

    const motionPlan = await timeoutHandle.race(motionPlanResponse.complete());

    return motionPlan;
  }

  private getModifiedPose(
    targetPose: CartesianPose,
    combinedProposal: DeviceOffsetProposal<DeviceCommand>[],
  ): CartesianPose | null {
    let pose = targetPose;

    for (const { deviceOffset } of combinedProposal) {
      switch (deviceOffset.kind) {
        case 'base':
          pose = applyCompoundPose(pose, invertPose(deviceOffset.transform));

          break;
        case 'tcp':
          pose = applyCompoundPose(
            tooltipCoordinatesToBaseCoordinates(
              invertPose(deviceOffset.transform),
            ),
            pose,
          );

          break;
        default:
          break;
      }
    }

    // the js `inverseKinematics` function is used to filter out invalid poses
    // before sending to motion planner, which is more accurate
    const ikResult = inverseKinematics(
      pose,
      this.jointLimits,
      this.request.startingJointPositions,
    );

    if (ikResult[0]) {
      return pose;
    }

    return null;
  }

  private async planMotionWithCombinedProposal(
    combinedProposal: DeviceOffsetProposal<DeviceCommand>[],
  ): Promise<MotionPlan> {
    const modifiedTargets = this.request.targets.map<ArmTarget>((target) => {
      if ('pose' in target) {
        const pose = this.getModifiedPose(target.pose, combinedProposal);

        if (!pose) {
          log.debug('pose.impossible', 'Pose not possible', { pose });
          throw Error('No inverse kinematics found for pose');
        }

        return {
          ...target,
          pose,
        };
      }

      return target;
    }) as MotionPlanRequest['targets'];

    const motionPlan = await this.planMotion({
      ...this.request,
      targets: modifiedTargets,
    });

    log.info('targets.modified', 'Targets modified', {
      from: this.request.targets,
      to: modifiedTargets,
    });

    return motionPlan;
  }
}
