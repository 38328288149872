import * as zod from 'zod';

import { Expression } from '@sb/routine-runner/types/expressions/Expression';

export namespace RoutineRunnerEnvironmentVariable {
  export const Item = zod.object({
    id: zod.string(),
    name: zod.string(),
    initialValue: Expression.optional(),
    isGlobal: zod.boolean().optional().default(false),
  });

  export type Item = zod.infer<typeof Item>;
}
