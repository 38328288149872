import * as zod from 'zod';

export const EsabWeldMachineConfiguration = zod.object({
  kind: zod.literal('EsabWeldMachine'),
  name: zod.string().default('EsabWeldMachine'),
  connectionOptions: zod
    .object({
      hostname: zod.string().default(''),
      port: zod.number().default(502),
    })
    .default({}),
});

export type EsabWeldMachineConfiguration = zod.infer<
  typeof EsabWeldMachineConfiguration
>;
