import type { EnvironmentVariable } from '@sb/remote-control/types';

import { getGlobalVariablesService } from './utils/getGlobalVariablesService';

export async function updateGlobalVariable(
  globalVariableId: string,
  updatedVariable: EnvironmentVariable,
): Promise<void> {
  const { id, name, initialValue } = updatedVariable;

  await getGlobalVariablesService().patch(globalVariableId, {
    globalVariableItem: {
      id,
      name,
      initialValue,
      isGlobal: true,
    },
  });
}
