import type { TaskFromRobotGoalActionRequest } from '@sb/ros/TaskFromRobotGoalAction';
import {
  roundToDecimalPlaces,
  roundToDecimalPlacesRadians,
} from '@sb/utilities';

/**
 * Get a ROS motion plan cache key.
 *
 * @param action Action to be cached
 * @param decimalPlaces All numbers are rounded to `decimalPlaces`
 * @param radiansDecimalPlaces Joint angles stated in radians are rounded to `radiansDecimalPlaces`.
 * @returns A cache key.
 */
export const getRosMotionPlanCacheKey = (
  action: TaskFromRobotGoalActionRequest,
  decimalPlaces = 3,
  radiansDecimalPlaces = 2,
): string => {
  return JSON.stringify(action, (k, val) => {
    if (['jointAngles', 'startJointState'].includes(k)) {
      if (!val?.length) {
        throw new Error(`Key '${k}' w/ value=${val} is not array-like`);
      }

      const arr = [...val].map((v: number) =>
        roundToDecimalPlacesRadians(v, radiansDecimalPlaces).toString(),
      );

      // Return as string so that they're not processed as individual numbers
      return `[${arr.join(',')}]`;
    }

    if (typeof val === 'number') {
      return roundToDecimalPlaces(val, decimalPlaces);
    }

    return val;
  });
};
