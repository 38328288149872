import '@sb/design-system';
/* remove the next import when everything is moved over to the new design system */
import '@sb/ui/global-tailwind.css';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import AppProvider from '@sbrc/components/AppProvider';
import { ErrorBoundary } from '@sbrc/components/ErrorBoundary';
import { pollSoftwareBuildData } from '@sbrc/hooks';

import ErrorPage from './404';
import { sentryCreateBrowserRouter } from './initSentry';

// converter for react-router:
// - wrap page component in error boundary
// - named export `Component`
function page<T extends { default: React.ComponentType }>(
  importer: () => Promise<T>,
) {
  return async () => {
    const mdl = await importer();

    return {
      ...mdl,
      Component() {
        return (
          <ErrorBoundary>
            <mdl.default />
          </ErrorBoundary>
        );
      },
    };
  };
}

const router = sentryCreateBrowserRouter([
  // root
  {
    path: '/',
    lazy: page(() => import('./main')),
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    lazy: page(() => import('./login')),
  },
  {
    path: '/logout',
    lazy: page(() => import('./logout')),
  },
  {
    path: '/privacy',
    lazy: page(() => import('./privacy')),
  },
  {
    path: '/terms',
    lazy: page(() => import('./terms')),
  },

  // control-panel
  {
    path: '/control-panel',
    lazy: page(() => import('./control-panel')),
  },
  {
    path: '/control-panel/demos',
    lazy: page(() => import('./control-panel/demos')),
  },
  {
    path: '/control-panel/equipment-inspector',
    lazy: page(() => import('./control-panel/equipment-inspector')),
  },
  {
    path: '/control-panel/feature-flags',
    lazy: page(() => import('./control-panel/feature-flags')),
  },
  {
    path: '/control-panel/firmware-inspector',
    lazy: page(() => import('./control-panel/firmware-inspector')),
  },
  {
    path: '/control-panel/simulator-bot',
    lazy: page(() => import('./control-panel/simulator-bot')),
  },
  {
    path: '/control-panel/config-editor',
    lazy: page(() => import('./control-panel/config-editor')),
  },

  // demo
  {
    path: '/demo/ey',
    lazy: page(() => import('./demo/ey')),
  },
  {
    path: '/demo/skills',
    lazy: page(() => import('./demo/skills')),
  },

  // help
  {
    path: '/help',
    lazy: page(() => import('./help')),
  },
  {
    path: '/help/:article',
    lazy: page(() => import('./help/[article]')),
  },

  // robot
  {
    path: '/box',
    lazy: page(() => import('./box')),
  },
  {
    path: '/move',
    lazy: page(() => import('./move')),
  },
  {
    path: '/preflight',
    lazy: page(() => import('./preflight')),
  },
  {
    path: '/scene',
    lazy: page(() => import('./scene')),
  },

  // robots
  {
    path: '/robots',
    lazy: page(() => import('./robots')),
  },

  // routines
  {
    path: '/routines',
    lazy: page(() => import('./routines')),
  },
  {
    path: '/routines/:routineID',
    lazy: page(() => import('./routines/[routineID]')),
  },
  //
  {
    path: '/training-data',
    lazy: page(() => import('./training-data')),
  },
  {
    path: '/training-data/:taskID',
    lazy: page(() => import('./training-data/[taskID]')),
  },
]);

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </StrictMode>,
);

pollSoftwareBuildData();
