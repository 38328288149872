import WebSocket from 'isomorphic-ws';
import type * as zod from 'zod';

import { FailureKind } from '@sb/routine-runner/FailureKind';
import { EventEmitter } from '@sb/utilities';

import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

const RUN_SKILL_WS_TIMEOUT = 10_000;

const WS_TIMEOUT_CODE = 3008;
const WS_CLOSE_CODE = 1000;

export default class RunSkillStep extends Step<Arguments, Variables> {
  private events = new EventEmitter<{
    stop: void;
    complete: void;
  }>();

  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  private socket: WebSocket | null = null;

  /**
   * @internal
   */
  private timeout: ReturnType<typeof setTimeout> | null = null;

  /**
   * Used for pause/resume handling
   * @internal
   */
  private lastStartTime = new Date();

  /**
   * Used for pause/resume handling
   * @internal
   */
  private elapsedMilliseconds = 0;

  public initializeVariableState(): void {
    this.variables = {};
  }

  private getSocket(
    endpoint: string,
    openWebSocketTimeoutMS = RUN_SKILL_WS_TIMEOUT,
  ): Promise<WebSocket> {
    const ws = new WebSocket(endpoint);

    return new Promise<WebSocket>((resolve, reject) => {
      const timeoutID = setTimeout(() => {
        // stop trying to open
        // close code 3008 = timeout
        ws.close(WS_TIMEOUT_CODE);
        reject(new Error('Open WebSocket timeout'));
      }, openWebSocketTimeoutMS);

      ws.addEventListener('open', () => {
        clearTimeout(timeoutID);
        resolve(ws);
      });

      ws.addEventListener('error', (e) => {
        reject(
          new Error(`Could not open WebSocket to ${endpoint}: ${e.message}`),
        );
      });
    });
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    if (this.routineContext.robot.robotKind !== 'live') {
      return fail({
        failure: {
          kind: FailureKind.StepPlayFailure,
          stepKind: 'RunSkill',
        },
        failureReason: 'Run skill step not supported in visualizer',
      });
    }

    try {
      this.socket = await this.getSocket(
        this.args.inferenceMachineEndpoint,
        RUN_SKILL_WS_TIMEOUT,
      );

      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(
          JSON.stringify({
            kind: 'load_model',
            metadata: {
              model_path: this.args.modelPath,
            },
          }),
        );

        this.socket.send(
          JSON.stringify({
            kind: 'start_inference',
          }),
        );

        this.elapsedMilliseconds = 0;
        const promise = this.events.next('complete');
        this._resume();
        await promise;
      }
    } catch (error) {
      return fail({
        failure: {
          kind: FailureKind.StepPlayFailure,
          stepKind: 'RunSkill',
        },
        failureReason: `Failed to run skill: ${error?.message}`,
        error,
      });
    }
  }

  public _stop() {
    this._pause();

    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(
        JSON.stringify({
          kind: 'stop_inference',
        }),
      );

      this.socket.close(WS_CLOSE_CODE);
    }

    this.events.emit('stop');
  }

  public _pause(): void {
    this.routineContext.robot.setROSControlEnabled(false);

    if (this.timeout !== null) {
      clearTimeout(this.timeout);

      const elapsedSinceStart =
        new Date().getTime() - this.lastStartTime.getTime();

      this.elapsedMilliseconds += elapsedSinceStart;
    }
  }

  public _resume(): void {
    this.routineContext.robot.setROSControlEnabled(true);

    this.lastStartTime = new Date();
    let stopped = false;

    this.events.once('stop', () => {
      stopped = true;
    });

    this.timeout = setTimeout(
      () => {
        if (stopped) {
          return;
        }

        this.events.emit('complete');
      },
      this.args.timeout * 1000 - this.elapsedMilliseconds,
    );
  }
}
