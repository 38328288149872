import * as zod from 'zod';

import { WeldMachineMode } from '../types/WeldMachineMode';

export const WeldArguments = zod.object({
  voltage: zod.number(),
  mode: WeldMachineMode,
  craterFillTime: zod.number(), // how long to wait for crater fill
  arcStartTime: zod.number(), // how long to hold before moving});
  wireFeedSpeed: zod.number(),
  travelSpeed: zod.number(),
  stickoutOffset: zod.number(),
});

export type WeldArguments = zod.infer<typeof WeldArguments>;
