import { memoize } from 'lodash';

import { CNCMonitorMachineStepDatabase } from '@sb/integrations/implementations/CNCMachine/step-wizard/CNCMonitorMachine/CNCMonitorMachineStepDatabase';
import { CNCOperateAutodoorStepDatabase } from '@sb/integrations/implementations/CNCMachine/step-wizard/CNCOperateAutodoor/CNCOperateAutodoorStepDatabase';
import { CNCOperateWorkholdingStepDatabase } from '@sb/integrations/implementations/CNCMachine/step-wizard/CNCOperateWorkholding/CNCOperateWorkholdingStepDatabase';
import { CNCRunProgramStepDatabase } from '@sb/integrations/implementations/CNCMachine/step-wizard/CNCRunProgram/CNCRunProgramStepDatabase';
import { MoveVerticalLiftStepDatabase } from '@sb/integrations/implementations/dynamic-base/step-wizard/MoveVerticalLiftStepDatabase';
import { ActuateGripperStepDatabase } from '@sb/integrations/implementations/gripper-general/step-wizard/ActuateGripperStepDatabase';
import { HaasControlRegionStepDatabase } from '@sb/integrations/implementations/HaasMill/frontend/HaasControlRegionStepDatabase';
import { HaasRunProgramStepDatabase } from '@sb/integrations/implementations/HaasMill/frontend/HaasRunProgramStepDatabase';
import { ActuateScrewdriverStepDatabase } from '@sb/integrations/implementations/onRobot/OnRobotScrewdriver/step-wizard/ActuateScrewdriverStepDatabase';
import { ActuateVacuumStepDatabase } from '@sb/integrations/implementations/onRobot/OnRobotVGP20/step-wizard/ActuateVacuumStepDatabase';
import { WeldStepDatabase } from '@sb/integrations/implementations/WeldMachine/shared/step-wizard/Weld/WeldStepDatabase';
import type { EquipmentItem } from '@sb/integrations/types';
import {
  AddOffsetStep,
  AnticipatePayloadStep,
  ClassifyStep,
  CodeBlockStep,
  IfStep,
  LocateStep,
  LoopControlStep,
  LoopStep,
  MoveArmToTargetStep,
  NetworkRequestStep,
  OperateEquipmentStep,
  PressButtonStep,
  PushModeStep,
  RunInBackgroundStep,
  RunSkillStep,
  SetEnvironmentVariableStep,
  SetIOStep,
  WaitForConfirmationStep,
  WaitStep,
  TriggerFaultStep,
} from '@sb/remote-control/step';
import { Step } from '@sb/remote-control/types';
import type { StepKind } from '@sb/routine-runner';

const STEP_KIND_INFO: Record<StepKind, Step.StepKindInfo> = {
  ActuateGripper: ActuateGripperStepDatabase,
  ActuateScrewdriver: ActuateScrewdriverStepDatabase,
  ActuateVacuum: ActuateVacuumStepDatabase,
  AddOffset: AddOffsetStep,
  AnticipatePayload: AnticipatePayloadStep,
  Classify: ClassifyStep,
  CodeBlock: CodeBlockStep,
  CNCRunProgram: CNCRunProgramStepDatabase,
  CNCMonitorMachine: CNCMonitorMachineStepDatabase,
  CNCOperateAutodoor: CNCOperateAutodoorStepDatabase,
  CNCOperateWorkholding: CNCOperateWorkholdingStepDatabase,
  HaasControlRegion: HaasControlRegionStepDatabase,
  HaasRunProgram: HaasRunProgramStepDatabase,
  If: IfStep,
  Locate: LocateStep,
  Loop: LoopStep,
  LoopControl: LoopControlStep,
  MoveArmTo: MoveArmToTargetStep,
  MoveVerticalLift: MoveVerticalLiftStepDatabase,
  NetworkRequest: NetworkRequestStep,
  OperateEquipment: OperateEquipmentStep,
  PressButton: PressButtonStep,
  PushMode: PushModeStep,
  RunInBackground: RunInBackgroundStep,
  RunSkill: RunSkillStep,
  SetEnvironmentVariable: SetEnvironmentVariableStep,
  SetIO: SetIOStep,
  Wait: WaitStep,
  WaitForConfirmation: WaitForConfirmationStep,
  Weld: WeldStepDatabase,
  TriggerFault: TriggerFaultStep,
};

export function getStepKindInfo(stepKind: StepKind): Step.StepKindInfo {
  return (
    STEP_KIND_INFO[stepKind] ?? {
      // default if step kind doesn't exist (e.g. after downgrade)
      name: `${stepKind} (unknown)`,
      description: `Unrecognized step kind`,
      librarySection: 'Basic',
    }
  );
}

export const getStepKindLibrary = memoize(() => {
  const libraryStepKinds = Object.entries(STEP_KIND_INFO)
    .map(([stepKind, { librarySection, librarySort, name }]) => {
      return {
        stepKind: stepKind as StepKind,
        librarySection,
        sort: `${librarySort ?? 'z'} ${name}`,
      };
    })
    .sort((a, b) => a.sort.localeCompare(b.sort));

  return Object.values(Step.LibrarySection).map((title) => ({
    title,
    stepKinds: libraryStepKinds
      .filter(({ librarySection }) => librarySection === title)
      .map(({ stepKind }) => stepKind),
  }));
});

export function isEquipmentRequiredForStepKind(
  stepKind: StepKind,
  equipment: EquipmentItem[],
): boolean {
  const stepDeviceKinds = getStepKindInfo(stepKind).deviceKinds;

  if (!stepDeviceKinds) {
    return false;
  }

  return !equipment.some((device) =>
    stepDeviceKinds.includes(device.config.kind),
  );
}
