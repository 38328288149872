/**
 * Routine specification constructed as a zod schema for static and
 * dynamic validation.
 */
import * as zod from 'zod';

import { RoutineStepConfiguration } from './Step';
import { Expression, Space } from './types';

export const Routine = zod.object({
  id: zod.string(),
  name: zod.string(),
  steps: zod.array(RoutineStepConfiguration).nonempty(),
  mainLoop: zod.string().optional(),
  environmentVariables: zod // only local
    .array(
      zod.object({
        id: zod.string(),
        initialValue: Expression.optional(),
        name: zod.string(),
        isGlobal: zod.boolean().optional().default(false),
      }),
    )
    .optional(),
  space: zod.array(Space.Item).optional(),
});

export type Routine = zod.infer<typeof Routine>;
