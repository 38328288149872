import * as zod from 'zod';

import { WeldConnectionOptions } from '../../shared/types/ConnectionOptions';

export const WeldMachineConfiguration = zod.object({
  kind: zod.literal('WeldMachine'),
  name: zod.string().default('WeldMachine'),
  connectionOptions: WeldConnectionOptions.default({}),
});

export type WeldMachineConfiguration = zod.infer<
  typeof WeldMachineConfiguration
>;
