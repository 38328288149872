import * as zod from 'zod';

import { IntegrationInterface } from '@sb/integrations/io/types';
import {
  CustomEquipmentActionConfiguration,
  CustomEquipmentConfiguration,
  CustomEquipmentSensorConfiguration,
} from '@sb/integrations/types/customEquipmentTypes';

// Custom Device Action Configuration
export const CustomDeviceActionConfiguration =
  CustomEquipmentActionConfiguration.extend({
    // Add any CustomDevice-specific action fields here if needed
  });

export type CustomDeviceActionConfiguration = zod.infer<
  typeof CustomDeviceActionConfiguration
>;

// Custom Device Sensor Configuration
export const CustomDeviceSensorConfiguration =
  CustomEquipmentSensorConfiguration;

export type CustomDeviceSensorConfiguration = zod.infer<
  typeof CustomDeviceSensorConfiguration
>;

export const CustomDeviceConfiguration = CustomEquipmentConfiguration.extend({
  kind: zod.literal('CustomDevice'),
  name: zod.string().default('Custom Device'),
  interfaces: IntegrationInterface.array().default([{ kind: 'controlBoxIO' }]),
  sensors: zod.array(CustomDeviceSensorConfiguration).default([]),
  actions: zod.array(CustomDeviceActionConfiguration).default([]),
  customIcon: zod.string().nullable().default(null),
});

export type CustomDeviceConfiguration = zod.infer<
  typeof CustomDeviceConfiguration
>;
