import * as zod from 'zod';

import { IntegrationInterfaceKind } from './IntegrationInterfaceKind';
import { ModbusTCP } from './ModbusTCP';

export const IntegrationActionModbusTCPInterface = zod.object({
  kind: zod.literal(IntegrationInterfaceKind.Values.modbusTCP),
  operations: ModbusTCP.Operation.array().default([]),
  inputs: zod.array(ModbusTCP.Parameter).default([]),
  outputs: zod.array(ModbusTCP.Parameter).default([]),
});

export type IntegrationActionModbusTCPInterface = zod.infer<
  typeof IntegrationActionModbusTCPInterface
>;
