import type { CacheBackend } from './ReadThroughCache';

/**
 * Read through cache that will return the cached value if available, otherwise it will
 * call the `getValue` function to get the value and cache it.
 *
 * @param Key The type of the key used to get the value.
 * @param Value The type of the value to be cached.
 */
export class ReadThroughCacheAsync<Key, Value> {
  public getCount: number = 0;

  public missCount: number = 0;

  protected cacheBackend: CacheBackend<Value>;

  private readonly getValue: (key: Key) => Promise<Value>;

  private readonly getCacheKey: (key: Key) => any;

  /**
   * @param getValue Function to fetch the value if the key is not in the cache
   * @param getCacheKey Function to transform the key to a cache key
   * @param cache
   */
  public constructor({
    getValue,
    getCacheKey = (key) => key,
    cacheBackend,
  }: {
    getValue: (key: Key) => Promise<Value>;
    getCacheKey?: (key: Key) => any;
    cacheBackend: CacheBackend<Value>;
  }) {
    this.cacheBackend = cacheBackend;
    this.getValue = getValue;
    this.getCacheKey = getCacheKey;
  }

  public async get(key: Key): Promise<Value> {
    const cacheKey = this.getCacheKey(key);

    this.getCount += 1;

    const cachedValue = this.cacheBackend.get(cacheKey);

    if (cachedValue === undefined) {
      this.missCount += 1;
      const value = await this.getValue(key);
      this.cacheBackend.set(cacheKey, value);

      return value;
    }

    return cachedValue;
  }

  public set(key: Key, value: Value) {
    this.cacheBackend.set(this.getCacheKey(key), value);
  }

  public has(key: Key) {
    return this.cacheBackend.has(this.getCacheKey(key));
  }

  public reset(): void {
    this.cacheBackend.reset();
    this.getCount = 0;
    this.missCount = 0;
  }

  public get hitCount(): number {
    return this.getCount - this.missCount;
  }

  public size(): number {
    return this.cacheBackend.size();
  }
}
