import * as zod from 'zod';

import { CartesianPose, ZERO_POSE } from '@sb/geometry';
import { CameraIntegration } from '@sb/integrations/types/cameraTypes';
import { ArmJointPositions, TCPOffsetOption } from '@sb/motion-planning';

export namespace Space {
  export const Position = zod.object({
    name: zod.string().optional(),
    jointAngles: ArmJointPositions,
    pose: CartesianPose,
    tcpOption: TCPOffsetOption.optional(),
    wristPose: CartesianPose.optional(),
  });

  export type Position = zod.infer<typeof Position>;

  export const CalibratedPosition = zod.object({
    rawPosition: Position,
    calibratedPosition: Position.nullable().default(null),
    offset: Position.nullable().default(null),
  });

  export type CalibratedPosition = zod.infer<typeof CalibratedPosition>;

  const ItemBase = zod.object({
    description: zod.string(),
    id: zod.string(),
    kind: zod.string(),
    name: zod.string(),
    positions: zod.array(Position).default([]),
    global: zod.boolean().default(false).optional(),
    anchoredToID: zod.string().optional(),
  });

  export const FreeformPositionList = ItemBase.extend({
    kind: zod.literal('freeformPositionList'),
  });

  export type FreeformPositionList = zod.infer<typeof FreeformPositionList>;

  export const SinglePosition = ItemBase.extend({
    kind: zod.literal('singlePosition'),
  });

  export type SinglePosition = zod.infer<typeof SinglePosition>;

  export const GridPositionList = ItemBase.extend({
    cornerA: Position.nullable().default(null),
    cornerB: Position.nullable().default(null),
    cornerC: Position.nullable().default(null).optional(),
    cornerD: Position.nullable().default(null).optional(),
    numColumns: zod.number().default(3),
    numRows: zod.number().default(3),
    kind: zod.literal('gridPositionList'),
  });

  export type GridPositionList = zod.infer<typeof GridPositionList>;

  export const VisionPositionList = ItemBase.extend({
    kind: zod.literal('visionPositionList'),
    planeID: zod.string().optional(),
  });

  export type VisionPositionList = zod.infer<typeof VisionPositionList>;

  export const AccuracyCalibrationEntry = zod.object({
    rawPose: CartesianPose,
    calibratedPose: CartesianPose,
    offset: CartesianPose,
  });

  export type AccuracyCalibrationEntry = zod.infer<
    typeof AccuracyCalibrationEntry
  >;

  export const Plane = ItemBase.extend({
    kind: zod.literal('plane'),
    capturedWithCameraKind: zod.string().optional(),
    calibrationHomePosition: Position.optional(),
    calibrationEntries: AccuracyCalibrationEntry.array().default([]),
    calibrationCameraSettings: CameraIntegration.default({
      kind: 'wristCamera',
    }),
  });

  export type Plane = zod.infer<typeof Plane>;

  export const EnvironmentObject = ItemBase.extend({
    kind: zod.literal('environmentObject'),
    fileURL: zod.string().nullable().default(null),
    fileName: zod.string().nullable().default(null),
    pose: CartesianPose.default(ZERO_POSE),
    scale: zod.number().optional(),
  });

  export type EnvironmentObject = zod.infer<typeof EnvironmentObject>;

  export const SafeHomePose = ItemBase.extend({
    kind: zod.literal('safeHomePose'),
  });

  export type SafeHomePose = zod.infer<typeof SafeHomePose>;

  export const LocalAccuracyCalibration = GridPositionList.extend({
    kind: zod.literal('localAccuracyCalibration'),
    calibrationEntries: Position.nullable().array().default([]),
    rowMeasurementMM: zod.number().default(0),
    columnMeasurementMM: zod.number().default(0),
  });

  export type LocalAccuracyCalibration = zod.infer<
    typeof LocalAccuracyCalibration
  >;

  export const Item = zod.discriminatedUnion('kind', [
    FreeformPositionList,
    GridPositionList,
    SinglePosition,
    VisionPositionList,
    Plane,
    EnvironmentObject,
    SafeHomePose,
    LocalAccuracyCalibration,
  ]);

  export type Item = zod.infer<typeof Item>;

  export type ItemKind = Item['kind'];

  export const AnchorableItem = zod.discriminatedUnion('kind', [
    FreeformPositionList,
    GridPositionList,
    SinglePosition,
    Plane,
  ]);

  export type AnchorableItem = zod.infer<typeof AnchorableItem>;

  export const AnchorTargetItem = zod.discriminatedUnion('kind', [
    SinglePosition,
    Plane,
    LocalAccuracyCalibration,
  ]);

  export type AnchorTargetItem = zod.infer<typeof AnchorTargetItem>;
}
