import type * as zod from 'zod';

import { FailureKind } from '@sb/routine-runner/FailureKind';

import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class TriggerFaultStep extends Step<Arguments, Variables> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  protected initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    const { message, isRecoverable } = this.args;

    return fail({
      failure: { kind: FailureKind.TriggerFaultFailure, isRecoverable },
      failureReason: message,
      error: new Error(message),
    });
  }
}
