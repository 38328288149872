import type { GlobalSpaceData } from '@sb/feathers-types';
import { makeNamespacedLog } from '@sb/log';
import { Space } from '@sb/routine-runner';

const log = makeNamespacedLog('convertGlobalSpaceResponse');

export function convertGlobalSpaceResponse(globalSpaceData: GlobalSpaceData): {
  globalSpaceID: string;
  spaceItem: Space.Item;
} | null {
  const parsedItem = Space.Item.safeParse(globalSpaceData.spaceItem);

  if (parsedItem.success) {
    const spaceItem = parsedItem.data;

    return { globalSpaceID: globalSpaceData.id, spaceItem };
  }

  log.warn('GlobalSpace', 'Failed to parse item', globalSpaceData.spaceItem);

  return null;
}
