import type {
  ForwardKinematicsRequest,
  ForwardKinematicsResponse,
  InverseKinematicsRequest,
  InverseKinematicsSolution,
} from '@sb/motion-planning';
import { WebSocketMotionPlanner } from '@sb/motion-planning';
import type { RosMotionPlanner } from '@sb/ros/RosMotionPlanner';
import { getFeatureFlagSync } from '@sb/service-interfaces/host/featureFlags';

// HybridMotionPlanner is a motion planner that can use either the WebSocketMotionPlanner or the RosMotionPlanner, for now, extends WebSocketMotionPlanner
// But in the future should implement the MotionPlanner interface
export class HybridMotionPlanner extends WebSocketMotionPlanner {
  private rosMotionPlanner: RosMotionPlanner;

  public constructor(
    rosMotionPlanner: RosMotionPlanner,
    webSocketMotionPlanner: WebSocketMotionPlanner,
  ) {
    super(''); // Required by JavaScript when extending a class. Pass an empty endpoint that gets assigned in the object assign
    this.rosMotionPlanner = rosMotionPlanner;
    Object.assign(this, webSocketMotionPlanner);
  }

  public async forwardKinematics(
    request: ForwardKinematicsRequest,
  ): Promise<ForwardKinematicsResponse> {
    if (getFeatureFlagSync('rosMigration', false)) {
      return await this.rosMotionPlanner.forwardKinematics(request);
    }

    return await super.forwardKinematics(request);
  }

  public async inverseKinematics(
    request: InverseKinematicsRequest,
  ): Promise<Array<InverseKinematicsSolution>> {
    if (getFeatureFlagSync('rosMigration', false)) {
      return await this.rosMotionPlanner.inverseKinematics(request);
    }

    return await super.inverseKinematics(request);
  }
}
