import type { Query } from '@feathersjs/feathers';

/**
 * Remove undefined values from query object.
 * Undefined works fine usually (as meaning no query),
 * but not when being tracked by feathers-reactive
 */
export function cleanQuery(query: Query): Query {
  for (const prop of Object.keys(query)) {
    if (query[prop] === undefined) {
      // eslint-disable-next-line no-param-reassign
      delete query[prop];
    }
  }

  return query;
}
