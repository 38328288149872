import { ActionRequiredError } from '@sb/remote-control/types';
import type { Expression } from '@sb/routine-runner';

/**
 * Validate a code expression.
 *
 * NOTE This should only be used as part of a step validator, not `toRoutineRunner`. Otherwise, will encounter errors on botman startup when go to load the routine.
 *
 * @param expression The expression to be validated.
 * @param fieldId Field ID where the expression is located
 * @param name Field where the expression is located
 */
export function validateCodeExpression(
  expression: Expression | undefined | null,
  fieldId?: string,
  name = fieldId,
): void {
  if (
    (expression?.kind === 'JavaScript' || expression?.kind === 'Python') &&
    expression.errorMessage
  ) {
    if (fieldId) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: `Error in ${name}: ${expression.errorMessage}`,
        fieldId,
      });
    } else {
      throw new Error(`Error in expression: ${expression.errorMessage}`);
    }
  }

  if (expression?.kind === 'JavaScript') {
    throw new ActionRequiredError({
      kind: 'deprecatedConfigurationWarning',
      message: `Expression uses deprecated syntax.`,
      fieldId,
      deprecationKind: 'javaScriptExpressions',
    });
  }
}
