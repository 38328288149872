import * as log from '@sb/log';
import { FailureKind } from '@sb/routine-runner';
import type { StepPlayArguments } from '@sb/routine-runner/Step/Step';
import Step from '@sb/routine-runner/Step/Step';
import { wait } from '@sb/utilities';

import { WeldArguments } from './Arguments';
import { WeldVariables } from './Variables';

const ns = log.namespace('HaasRunProgramStep');

export class WeldStep extends Step<WeldArguments, WeldVariables> {
  public static areSubstepsRequired = true;

  public static Arguments = WeldArguments;

  public static Variables = WeldVariables;

  public substeps: Array<Step<object, object>> = [];

  protected initializeVariableState(): void {
    this.variables = {};
  }

  private checkReady(): boolean {
    // TODO: implement
    return true;
  }

  public async _play({ fail, playSubSteps }: StepPlayArguments): Promise<void> {
    log.info(ns`weld.step`, 'Playing Weld step');

    const isReady = this.checkReady();

    if (!isReady) {
      fail({
        failure: {
          kind: FailureKind.WeldFailure,
        },
        failureReason: 'Weld machine is not ready',
      });
    }

    // this.routineContext.equipment.executeCommand({
    //   kind: 'WeldMachineCommand',
    //   subCommand: {
    //     kind: 'startArc',
    //     mode: this.args.mode,
    //     voltage: this.args.voltage,
    //   },
    // });

    wait(this.args.arcStartTime);

    // this.routineContext.equipment.executeCommand({
    //   kind: 'WeldMachineCommand',
    //   subCommand: { kind: 'startWireFeed', speed: this.args.wireFeedSpeed },
    // });

    wait(this.args.craterFillTime);
    await playSubSteps();

    log.info(ns`weld.step`, 'move step finished');

    // this.routineContext.equipment.executeCommand({
    //   kind: 'WeldMachineCommand',
    //   subCommand: { kind: 'stopWireFeed' },
    // });

    // this.routineContext.equipment.executeCommand({
    //   kind: 'WeldMachineCommand',
    //   subCommand: { kind: 'stopArc' },
    // });

    return Promise.resolve();
  }

  public _stop(): void {}
}
