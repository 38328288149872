import type { IntegrationActionFlangeIOInterface } from '@sb/integrations/io/types';
import type { RobotInterface } from '@sb/routine-runner';
import { wait } from '@sb/utilities';

export type ExecuteFlangeIOActionDeps = {
  robot: Pick<RobotInterface, 'setOutputIO'>;
};

export async function executeFlangeIOAction(
  actionInterface: IntegrationActionFlangeIOInterface,
  { robot }: ExecuteFlangeIOActionDeps,
) {
  const changes: Parameters<typeof robot.setOutputIO>[0] = [];
  const pulseChanges: Parameters<typeof robot.setOutputIO>[0] = [];

  for (const { ioPort, isHigh, isPulse } of actionInterface.outputs) {
    if (!ioPort) {
      continue;
    }

    changes.push({ label: ioPort, level: isHigh ? 'high' : 'low' });

    if (isPulse) {
      pulseChanges.push({ label: ioPort, level: isHigh ? 'low' : 'high' });
    }
  }

  if (changes.length > 0) {
    await robot.setOutputIO(changes);
  }

  if (pulseChanges.length > 0) {
    await wait(actionInterface.pulseSeconds * 1000);
    await robot.setOutputIO(pulseChanges);
  }
}
