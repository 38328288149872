import type { VariableInformation } from './types';

export function getJavaScriptExpressionForVariable(
  info: VariableInformation,
): string {
  const identifier = info.identifier ?? info.variableName;
  const { dynamicKey, transformResult } = info;
  let result = '';

  if (identifier != null && identifier !== '') {
    if (dynamicKey != null && dynamicKey !== '') {
      result = `__get("${identifier}","${info.userIdentifier}", "${dynamicKey}")`;
    } else {
      result = `__get("${identifier}","${info.userIdentifier}")`;
    }

    if (transformResult != null) {
      result = `(${result} ${transformResult.comparison} ? ${transformResult.trueResult} : ${transformResult.falseResult})`;
    }

    return result;
  }

  return `__get("${identifier}","${info.userIdentifier}")`;
}

/**
 * Check expression validity and replace user facing variable names with internal getters
 */
export default function convertJavaScriptExpressionFromUser(
  userExpression: string,
  variableInformation: VariableInformation[],
): {
  expression: string;
  errorMessage: string | null;
} {
  const errorMessages: string[] = [];

  if (userExpression.trim()) {
    try {
      // syntax validation - will throw if invalid
      // eslint-disable-next-line no-new,@typescript-eslint/no-implied-eval
      new Function(userExpression);
    } catch (error) {
      errorMessages.push(error.message);
    }
  }

  const expression = userExpression.replace(
    /\b(Environment|Gripper|IO|Invalid|Space|Step|Sensor)_\w+\b/gi,
    (match) => {
      const info = variableInformation.find(
        ({ userIdentifier }) => userIdentifier === match,
      );

      if (info) {
        return getJavaScriptExpressionForVariable(info);
      }

      errorMessages.push(`Variable “${match}” is not valid`);

      return `__get("INVALID","${match}")`;
    },
  );

  const errorMessage =
    errorMessages.length === 0 ? null : errorMessages.join('; ');

  return {
    expression,
    errorMessage,
  };
}
