import * as zod from 'zod';

import { WeldConnectionOptions } from '../../shared/types/ConnectionOptions';

export const MillerWeldMachineConfiguration = zod.object({
  kind: zod.literal('MillerWeldMachine'),
  name: zod.string().default('MillerWeldMachine'),
  connectionOptions: WeldConnectionOptions.default({}),
});

export type MillerWeldMachineConfiguration = zod.infer<
  typeof MillerWeldMachineConfiguration
>;
