import { useEffect } from 'react';
import { create } from 'zustand';

import { makeLogNamespace } from '@sb/log/log';
import type { EnvironmentVariable } from '@sb/remote-control/types';
import { createGlobalVariable } from '@sbrc/services/feathers-client/global-variables/createGlobalVariable';
import { deleteGlobalVariable } from '@sbrc/services/feathers-client/global-variables/deleteGlobalVariable';
import { onGetAllGlobalVariables } from '@sbrc/services/feathers-client/global-variables/onGetAllGlobalVariables';
import { updateGlobalVariable } from '@sbrc/services/feathers-client/global-variables/updateGlobalVariable';

const log = makeLogNamespace('GlobalVariablesStore');

interface GlobalVariablesStore {
  globalVariables: EnvironmentVariable[];
  itemIdToGlobalKey: { [key: string]: string };
  isLoading: boolean;
  addGlobalVariable: (variable: EnvironmentVariable) => Promise<void>;
  modifyGlobalVariable: (variable: EnvironmentVariable) => Promise<void>;
  removeGlobalVariable: (variableId: string) => Promise<void>;
  initializeGlobalVariables: () => Promise<void>;
}

export const useGlobalVariablesStore = create<GlobalVariablesStore>(
  (set, get) => {
    return {
      globalVariables: [],
      itemIdToGlobalKey: {},
      isLoading: true,

      addGlobalVariable: async (variable: EnvironmentVariable) => {
        // Check for uniqueness
        const existingVariable = get().globalVariables.find(
          (v) => v.id === variable.id,
        );

        if (existingVariable) {
          return;
        }

        try {
          const globalId = await createGlobalVariable(variable);

          set((state) => ({
            globalVariables: [...state.globalVariables, variable],
            itemIdToGlobalKey: {
              ...state.itemIdToGlobalKey,
              [variable.id]: globalId, // Update the global key mapping
            },
          }));
        } catch (e) {
          log.error('addVariable.error', 'Failed to add global variable', {
            e,
          });
        }
      },

      modifyGlobalVariable: async (updatedVariable: EnvironmentVariable) => {
        try {
          const globalVariableItemId =
            get().itemIdToGlobalKey[updatedVariable.id];

          await updateGlobalVariable(globalVariableItemId, updatedVariable);

          // Update state with the modified variable
          set((state) => ({
            globalVariables: state.globalVariables.map((variable) =>
              variable.id === updatedVariable.id ? updatedVariable : variable,
            ),
          }));
        } catch (e) {
          log.error(
            'updateVariable.error',
            'Failed to update global variable',
            { e },
          );
        }
      },

      removeGlobalVariable: async (itemId: string) => {
        try {
          const globalVariableItemId = get().itemIdToGlobalKey[itemId];
          await deleteGlobalVariable(globalVariableItemId);

          // Remove the variable from state
          set((state) => ({
            globalVariables: state.globalVariables.filter(
              (v) => v.id !== itemId,
            ),
            itemIdToGlobalKey: Object.fromEntries(
              Object.entries(state.itemIdToGlobalKey).filter(
                ([key]) => key !== itemId,
              ),
            ),
          }));
        } catch (e) {
          log.error(
            'deleteVariable.error',
            'Failed to delete global variable',
            { e },
          );
        }
      },

      initializeGlobalVariables: async () => {
        set({ isLoading: true });

        try {
          const globalVariablesItemsWithID = await new Promise<any[]>(
            (resolve) => {
              const unsubscribe = onGetAllGlobalVariables((items) => {
                unsubscribe();
                resolve(items);
              });
            },
          );

          const itemIdToGlobalKey: { [key: string]: string } = {};
          const globalVariablesItems = [];

          for (const itemWithID of globalVariablesItemsWithID) {
            itemIdToGlobalKey[itemWithID.globalVariableItem.id] =
              itemWithID.globalVariableId;

            globalVariablesItems.push(itemWithID.globalVariableItem);
          }

          set({
            globalVariables: globalVariablesItems,
            itemIdToGlobalKey,
            isLoading: false,
          });
        } catch (error) {
          log.error(
            'initializeGlobalVariables.error',
            'Failed to initialize global variables',
            { error },
          );

          set({ isLoading: false });
        }
      },
    };
  },
);

export function useInitializeGlobalVariables() {
  const initializeGlobalVariables = useGlobalVariablesStore(
    (state) => state.initializeGlobalVariables,
  );

  useEffect(() => {
    initializeGlobalVariables();
  }, [initializeGlobalVariables]);
}
