import * as zod from 'zod';

const IndexedNumberObject = zod
  .record(zod.number())
  .transform((object): number[] => Object.values(object));

const NumberArrayOrIndexedNumberObject = zod.union([
  zod.array(zod.number()),
  IndexedNumberObject,
]);

export const StdMsgsTime = zod.object({
  sec: zod.number(),
  nanosec: zod.number(),
});

export type StdMsgsTime = zod.infer<typeof StdMsgsTime>;

export const StdMsgsHeader = zod.object({
  stamp: StdMsgsTime,
  frame_id: zod.string(),
});

export type StdMsgsHeader = zod.infer<typeof StdMsgsHeader>;

export const TrjMsgsJointTrajectoryPoint = zod.object({
  positions: NumberArrayOrIndexedNumberObject,
  velocities: NumberArrayOrIndexedNumberObject,
  accelerations: NumberArrayOrIndexedNumberObject,
  effort: NumberArrayOrIndexedNumberObject,
  time_from_start: StdMsgsTime,
});

export type TrjMsgsJointTrajectoryPoint = zod.infer<
  typeof TrjMsgsJointTrajectoryPoint
>;

export const TrjMsgsJointTrajectory = zod.object({
  header: StdMsgsHeader,
  joint_names: zod.array(zod.string()),
  points: zod.array(TrjMsgsJointTrajectoryPoint),
});

export const TaskFromRobotGoalActionResult = zod.object({
  success: zod.boolean(),
  trajectories: zod.array(TrjMsgsJointTrajectory),
});

export type TaskFromRobotGoalActionResult = zod.infer<
  typeof TaskFromRobotGoalActionResult
>;
