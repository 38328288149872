const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';

const isLocalhost = window.location.hostname === 'localhost';

export const LSP_ENDPOINT = isLocalhost
  ? `http://localhost:3012/`
  : `${window.location.protocol}//${window.location.host}/`;

export const API_ENDPOINT = `${wsProtocol}//${window.location.host}/`;
export const HTTP_API_ENDPOINT = window.origin;
