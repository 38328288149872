import { getJavaScriptExpressionForVariable } from './convertJavaScriptExpressionFromUser';
import type { VariableInformation } from './types';

const INVALID = 'Invalid_';

interface Options {
  variables: VariableInformation[];
  isForStepDescription: boolean;
}

const DEFAULT_OPTIONS: Options = {
  variables: [],
  isForStepDescription: false,
};

/** Replace internal variable getters to user facing variable names */
export default function convertJavaScriptExpressionToUser(
  expression: string,
  optionsOrVariables: VariableInformation[] | Partial<Options>,
): string {
  const options: Options = Array.isArray(optionsOrVariables)
    ? { ...DEFAULT_OPTIONS, variables: optionsOrVariables }
    : { ...DEFAULT_OPTIONS, ...optionsOrVariables };

  let userExpression = expression;

  for (const variable of options.variables) {
    if (variable.userIdentifier == null) {
      continue;
    }

    const jsExpression = getJavaScriptExpressionForVariable(variable);

    const escapedJsExpression = jsExpression.replace(
      /[.*+?^${}()|[\]\\]/g,
      '\\$&',
    );

    userExpression = userExpression.replace(
      new RegExp(escapedJsExpression, 'g'),
      variable.userIdentifier,
    );
  }

  userExpression = userExpression.replace(
    /\b__get\(("([^"]+)","(\w+)"[^)]*|[^)]+)\)/g,
    (_matchAll, _matchGet, matchIdentifier, matchUserIdentifier) => {
      if (matchIdentifier && matchUserIdentifier) {
        if (
          matchUserIdentifier.startsWith(INVALID) ||
          options.isForStepDescription
        ) {
          return matchUserIdentifier;
        }

        const info = options.variables.find(
          ({ variableName, identifier = variableName }) =>
            identifier === matchIdentifier,
        );

        if (info?.userIdentifier) {
          return info.userIdentifier;
        }

        return `${INVALID}${matchUserIdentifier}`;
      }

      return `${INVALID}variable`;
    },
  );

  return userExpression;
}
