// @ts-ignore
import type * as RCL from 'rclnodejs';

import { type CartesianPose } from '@sb/geometry';
import { info, namespace } from '@sb/log';
import type { ArmJointPositions, FrameOfReference } from '@sb/motion-planning';
import { getRCL } from '@sb/ros/getRCL';
import type { SpeedProfile } from '@sb/routine-runner/speed-profile/SpeedProfile';

import { BaseAction } from './BaseAction';
import { JOINT_NAMES } from './constants';

const ns = namespace('JogAction');

export type JoggingActionRequest = {
  startJointState: number[];
  speedProfile: SpeedProfile;
} & (
  | {
      jogType: 'joint';
      displacements: ArmJointPositions;
    }
  | {
      jogType: 'cartesian';
      pose: CartesianPose;
      frame: FrameOfReference;
    }
);

export class JoggingAction extends BaseAction<
  'standard_bots_msgs/action/Jogging',
  RCL.standard_bots_msgs.action.Jogging_Goal,
  RCL.standard_bots_msgs.action.Jogging_Result
> {
  private request: JoggingActionRequest;

  public constructor(request: JoggingActionRequest) {
    super();
    this.request = request;
  }

  protected override getType(): RCL.TypeClass<keyof RCL.ActionsMap> {
    return 'standard_bots_msgs/action/Jogging';
  }

  protected override getTopic() {
    return '/jog';
  }

  protected override async createGoal() {
    const rcl = await getRCL();

    const joggingAction = rcl.require('standard_bots_msgs/action/Jogging');

    const goalMessage = new joggingAction.Goal();

    info(ns`createGoal`, 'creating jogging goal message');

    // Set joint jog command
    if (this.request.jogType === 'joint') {
      goalMessage.joint_jog_cmd = this.createJointJogCmd(rcl);
      goalMessage.jog_cmd = joggingAction.Goal.JOINT_CMD;
    } else {
      // this.request.jogType === 'cartesian'
      goalMessage.cartesian_jog_cmd = this.createCartesianJogCmd(rcl);
      goalMessage.jog_cmd = joggingAction.Goal.CARTESIAN_CMD;
    }

    // Set start joint state
    goalMessage.start_joint_state = this.getStartJointState(rcl);

    info(
      ns`createGoal`,
      `finished creating jogging goal message: ${JSON.stringify(goalMessage.toPlainObject())}`,
    );

    return goalMessage;
  }

  private createPlanOptions(rcl: typeof RCL) {
    const planOptions = rcl.createMessageObject(
      'standard_bots_msgs/msg/PlanOptions',
    );

    info(ns`createPlanOptions`, 'speedprofile', this.request.speedProfile);

    planOptions.velocity_scaling =
      this.request.speedProfile.baseVelocityScaling;

    planOptions.acceleration_scaling =
      this.request.speedProfile.baseAccelerationScaling;

    planOptions.max_acceleration_limit = [
      ...this.request.speedProfile.maxJointAccelerations,
    ];

    planOptions.max_velocity_limit = [
      ...this.request.speedProfile.maxJointSpeeds,
    ];

    planOptions.max_tooltip_velocity =
      this.request.speedProfile.maxTooltipSpeed;

    info(ns`createPlanOptions`, 'planOptions', planOptions);

    return planOptions;
  }

  private getStartJointState(rcl: typeof RCL) {
    const jointState = rcl.createMessageObject('sensor_msgs/msg/JointState');

    jointState.name = JOINT_NAMES;

    info(
      ns`getStartJointState`,
      `setting start positions to ${this.request.startJointState}`,
    );

    jointState.position = this.request.startJointState;

    return jointState;
  }

  private createJointJogCmd(rcl: typeof RCL) {
    if (this.request.jogType !== 'joint') {
      throw new Error('Joint jog command not provided');
    }

    const jointJogWithOptions = rcl.createMessageObject(
      'standard_bots_msgs/msg/JointJogWithOptions',
    );

    const now = new rcl.Clock().now();
    // Create and set JointJog message
    const jointJog = rcl.createMessageObject('control_msgs/msg/JointJog');
    jointJog.displacements = this.request.displacements;
    jointJog.velocities = []; // ROS2 side ignores this field
    jointJog.duration = 0; // ROS2 side ignores this field
    jointJog.joint_names = JOINT_NAMES;

    jointJog.header.stamp.sec = now.secondsAndNanoseconds.seconds;
    jointJog.header.stamp.nanosec = now.secondsAndNanoseconds.nanoseconds;
    jointJog.header.frame_id = 'world';

    jointJogWithOptions.joint_jog_cmd = jointJog;

    jointJogWithOptions.plan_options = this.createPlanOptions(rcl);

    return jointJogWithOptions;
  }

  private createCartesianJogCmd(rcl: typeof RCL) {
    if (this.request.jogType !== 'cartesian') {
      throw new Error('Cartesian jog command not provided');
    }

    const cartesianJog = rcl.createMessageObject(
      'standard_bots_msgs/msg/CartesianJog',
    );

    // Create and set PoseStamped message
    const pose = rcl.createMessageObject('geometry_msgs/msg/PoseStamped');

    const now = new rcl.Clock().now();

    pose.header.stamp.sec = now.secondsAndNanoseconds.seconds;
    pose.header.stamp.nanosec = now.secondsAndNanoseconds.nanoseconds;

    // ROS2 checks for 'world' frame_id, else any value is accepted
    pose.header.frame_id =
      this.request.frame === 'robotArm' ? 'world' : 'not-world';

    let ros2Pose: CartesianPose;

    if (this.request.frame === 'robotArm') {
      ros2Pose = this.request.pose;
    } else {
      // Rotate the axes to match ROS2's coordinate system
      ros2Pose = {
        x: this.request.pose.y,
        y: this.request.pose.z,
        z: this.request.pose.x,
        i: this.request.pose.j,
        j: this.request.pose.k,
        k: this.request.pose.i,
        w: this.request.pose.w,
      };
    }

    pose.pose.position.x = ros2Pose.x;
    pose.pose.position.y = ros2Pose.y;
    pose.pose.position.z = ros2Pose.z;

    pose.pose.orientation.x = ros2Pose.i;
    pose.pose.orientation.y = ros2Pose.j;
    pose.pose.orientation.z = ros2Pose.k;
    pose.pose.orientation.w = ros2Pose.w;

    cartesianJog.cartesian_jog_cmd = pose;

    cartesianJog.plan_options = this.createPlanOptions(rcl);

    return cartesianJog;
  }
}
