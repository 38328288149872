import type { GlobalVariableData } from '@sb/feathers-types';
import { RoutineRunnerEnvironmentVariable } from '@sb/routine-runner/types/Variable';

export function convertGlobalVariableResponse(
  globalVariableData: GlobalVariableData,
): {
  globalVariableId: string;
  globalVariableItem: RoutineRunnerEnvironmentVariable.Item;
} {
  const parsedItem = RoutineRunnerEnvironmentVariable.Item.safeParse(
    globalVariableData.globalVariableItem,
  );

  if (parsedItem.success) {
    return {
      globalVariableId: globalVariableData.id,
      globalVariableItem: parsedItem.data,
    };
  }

  throw new Error('Failed to parse item');
}
