import * as zod from 'zod';

import { FlangeOutputPortID } from '@sb/routine-runner/types/FlangeOutputPort';

import { IntegrationInterfaceKind } from './IntegrationInterfaceKind';

export const IntegrationActionFlangeIOOutputs = zod
  .object({
    ioPort: FlangeOutputPortID.nullable().default(null),
    isHigh: zod.boolean().default(true),
    isPulse: zod.boolean().default(true),
  })
  .array()
  .default([{}]);

export type IntegrationActionFlangeIOOutputs = zod.infer<
  typeof IntegrationActionFlangeIOOutputs
>;

export const IntegrationActionFlangeIOInterface = zod.object({
  kind: zod.literal(IntegrationInterfaceKind.Values.flangeIO),
  outputs: IntegrationActionFlangeIOOutputs,
  pulseSeconds: zod.number().default(0.5),
});

export type IntegrationActionFlangeIOInterface = zod.infer<
  typeof IntegrationActionFlangeIOInterface
>;
