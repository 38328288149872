import { map } from 'rxjs/operators';

import { mapPaginated } from '@sb/feathers-types';
import type {
  EnvironmentVariable,
  Unsubscribe,
} from '@sb/remote-control/types';
import { convertGlobalVariableResponse } from '@sb/remote-control/util/convertGlobalVariableResponse';

import { getGlobalVariablesService } from './utils/getGlobalVariablesService';

interface GlobalVariableSnapshot {
  globalVariableId: string;
  globalVariableItem: EnvironmentVariable;
}

export function onGetAllGlobalVariables(
  onSnapshot: (globalVariables: GlobalVariableSnapshot[]) => void,
): Unsubscribe {
  const subscription = getGlobalVariablesService()
    .watch()
    .find({ query: {} })
    .pipe(
      map((response) => {
        return mapPaginated(response, convertGlobalVariableResponse);
      }),
    )
    .subscribe(onSnapshot);

  // Return the unsubscribe function
  return () => subscription.unsubscribe();
}
