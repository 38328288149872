// @ts-ignore
import type * as RCL from 'rclnodejs';

import { getRCL } from '@sb/ros/getRCL';

import { BaseService } from './BaseService';

export class IKCollisionCheckService extends BaseService<
  'standard_bots_msgs/srv/IKCollisionCheck',
  RCL.standard_bots_msgs.srv.IKCollisionCheck_Request,
  RCL.standard_bots_msgs.srv.IKCollisionCheck_Response
> {
  private goal_pose: RCL.geometry_msgs.msg.Pose;

  private check_collision: boolean;

  public constructor(
    goal_pose: RCL.geometry_msgs.msg.Pose,
    check_collision: boolean,
  ) {
    super();
    this.goal_pose = goal_pose;
    this.check_collision = check_collision;
  }

  protected override getType(): RCL.TypeClass<keyof RCL.ServicesMap> {
    return 'standard_bots_msgs/srv/IKCollisionCheck';
  }

  protected override getServiceName() {
    return 'solve_ik';
  }

  protected override async createRequest() {
    const rcl = await getRCL();

    const IKCollisionCheck = rcl.require(
      'standard_bots_msgs/srv/IKCollisionCheck',
    );

    const request = new IKCollisionCheck.Request();

    request.goal_pose = this.goal_pose;
    request.check_collision = this.check_collision;

    return request;
  }
}
