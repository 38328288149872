import * as zod from 'zod';

import type { RoutineContext } from '@sb/routine-runner/RoutineContext';

export const PythonExpression = zod.object({
  kind: zod.literal('Python'),
  expression: zod.string(),
  errorMessage: zod.string().optional().nullable(),
});

export type PythonExpression = zod.infer<typeof PythonExpression>;

export type PyRunner = {
  evaluate(args: {
    code: string;
    contextKind: string;
    routineContext: RoutineContext;
  }): Promise<unknown>;
};
