import * as zod from 'zod';

export const RobotOperationMode = zod
  .enum(['live', 'simulated', 'assembly', 'echo'])
  .default('live');

export type RobotOperationMode = zod.infer<typeof RobotOperationMode>;

export const RobotIdentityData = zod.object({
  robotId: zod.string(),
  alias: zod.string(),
  cloudflareTunnelId: zod.string().optional(),
  applicationUrl: zod.string().nullable().default(null),
  robotOperationMode: RobotOperationMode,
});

export type RobotIdentityData = zod.infer<typeof RobotIdentityData>;
