import * as zod from 'zod';

import { WeldMachineMode } from '../../shared/types/WeldMachineMode';

export const EsabWeldMachineState = zod.object({
  kind: zod.literal('EsabWeldMachine'),
  isReady: zod.boolean(),
  voltage: zod.number(),
  current: zod.number(),
  wireFeedSpeed: zod.number(),
  mode: WeldMachineMode,
  isTestMode: zod.boolean(),
});

export type EsabWeldMachineState = zod.infer<typeof EsabWeldMachineState>;
