import { useContext, useCallback } from 'react';

import type { ToastState } from '@sb/ui/components';
import { GlobalContext } from '@sbrc/contexts';
import { createNotification } from '@sbrc/services';

import useRobotList from './useRobotList';

const isMessageString = (message: React.ReactNode | string) => {
  return typeof message === 'string';
};

const useToast = () => {
  const { toast } = useContext(GlobalContext);
  const robots = useRobotList();
  const { state, set } = toast;
  const robotID = robots?.[0]?.id;

  const setToast = useCallback(
    (newToast: ToastState | null) => {
      if (newToast?.kind === 'error') {
        // eslint-disable-next-line no-console
        console.error({
          kind: 'toastError',
          newToast,
        });

        if (newToast.error) {
          // eslint-disable-next-line no-console
          console.error(newToast.error);
        } else {
          // eslint-disable-next-line no-console
          console.trace(`Toast error ${newToast.message}`);
        }
      }

      if (robotID && newToast && isMessageString(newToast?.message)) {
        createNotification({
          kind: 'toast',
          robotID,
          failureReason: newToast.message as string,
          failureKind: newToast.kind,
        });
      }

      set(newToast);
    },
    [robotID, set],
  );

  return { toastState: state, setToast };
};

export default useToast;
