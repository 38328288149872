import type { Robot } from '@sb/remote-control/types';
import {
  FLANGE_INPUT_PORT_IDS,
  FLANGE_OUTPUT_PORT_IDS,
  SAFETY_SAFEGUARD_PAIRS,
} from '@sb/routine-runner';

export function getSafetyPorts(
  tabletCaseCompatible: boolean,
): Array<Robot.IOPort> {
  const safetyIOPorts: Array<Robot.IOPort> = [];

  for (const pair of SAFETY_SAFEGUARD_PAIRS) {
    if (pair.longLabel === 'Tablet Case E-Stop' && !tabletCaseCompatible) {
      continue;
    }

    safetyIOPorts.push({
      highSignalName: 'Run',
      kind: 'Safety',
      lowSignalName: 'Stop',
      name: `${pair.longLabel} 1`,
      port: 1,
      shortName: pair.shortLabel,
      portID: pair.ports[0],
    });

    safetyIOPorts.push({
      highSignalName: 'Run',
      kind: 'Safety',
      lowSignalName: 'Stop',
      name: `${pair.longLabel} 2`,
      port: 2,
      shortName: pair.shortLabel,
      portID: pair.ports[1],
    });
  }

  return safetyIOPorts;
}

export const FLANGE_PINS = [
  'Analog Input', // PIN 1
  'Analog Input', // PIN 2
  'Flange Input 2', // PIN 3
  'Flange Input 1', // PIN 4
  'Power', // PIN 5
  'Flange Output 2', // PIN 6
  'Flange Output 1', // PIN 7
  'Ground', // PIN 8
];

export function getFlangePorts(): Array<Robot.IOPort> {
  const flangePorts = [
    ...FLANGE_INPUT_PORT_IDS.map(
      (flangePort, index) =>
        ({
          highSignalName: 'High',
          kind: 'FlangeInput',
          lowSignalName: 'Low',
          name: flangePort,
          port: index + 1,
          shortName: `PIN ${FLANGE_PINS.indexOf(flangePort) + 1}`,
          portID: flangePort,
        }) as Robot.IOPort,
    ),
    ...FLANGE_OUTPUT_PORT_IDS.map(
      (flangePort, index) =>
        ({
          highSignalName: 'High',
          kind: 'FlangeOutput',
          lowSignalName: 'Low',
          name: flangePort,
          port: index + 1,
          shortName: `PIN ${FLANGE_PINS.indexOf(flangePort) + 1}`,
          portID: flangePort,
        }) as Robot.IOPort,
    ),
  ];

  return flangePorts;
}
