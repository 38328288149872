import type { Routine } from '../Routine';
import type { RoutineStepConfiguration } from '../Step';

export type TRoutineVariablesIdMap = {
  environment: Record<string, string>;
  step: Record<string, string>;
  space: Record<string, string>;
};

/**
 * Routine context variables with nested objects in form `{[ID]: [name]}`
 */
export const getIdNameMap = (routine?: Routine): TRoutineVariablesIdMap => {
  const environment = (routine?.environmentVariables || []).reduce(
    (acc, ev) => {
      acc[ev.id] = ev.name;

      return acc;
    },
    {} as Record<string, string>,
  );

  const space = (routine?.space || []).reduce(
    (acc, s) => {
      acc[s.id] = s.name;

      return acc;
    },
    {} as Record<string, string>,
  );

  const step = ((routine?.steps || []) as RoutineStepConfiguration[]).reduce(
    (acc, s, i) => {
      acc[s.id] = `${i + 1}`;

      return acc;
    },
    {} as Record<string, string>,
  );

  return {
    environment,
    space,
    step,
  };
};

/**
 * Routine context variables with nested objects in form `{[name]: [ID]}`
 */
export const getNameIdMap = (routine?: Routine): TRoutineVariablesIdMap => {
  const environment = (routine?.environmentVariables || []).reduce(
    (acc, ev) => {
      acc[ev.name] = ev.id;

      return acc;
    },
    {} as Record<string, string>,
  );

  const space = (routine?.space || []).reduce(
    (acc, s) => {
      acc[s.name] = s.id;

      return acc;
    },
    {} as Record<string, string>,
  );

  const step = ((routine?.steps || []) as RoutineStepConfiguration[]).reduce(
    (acc, s, i) => {
      acc[`${i + 1}`] = s.id;

      return acc;
    },
    {} as Record<string, string>,
  );

  return {
    environment,
    space,
    step,
  };
};
