import type { EnvironmentVariable } from '@sb/remote-control/types';

import { getGlobalVariablesService } from './utils/getGlobalVariablesService';

export async function createGlobalVariable(
  globalVariable: EnvironmentVariable,
): Promise<string> {
  const response = await getGlobalVariablesService().create({
    globalVariableItem: {
      id: globalVariable.id,
      name: globalVariable.name,
      initialValue: globalVariable.initialValue,
      isGlobal: globalVariable.isGlobal || true, // Default to true
    },
  });

  return response.id;
}
