import { memoize } from 'lodash';

import { WebSocketMotionPlanner } from '@sb/motion-planning';
import { RosMotionPlanner } from '@sb/ros/RosMotionPlanner';
import { HybridMotionPlanner } from '@sb/routine-runner';
import { API_ENDPOINT } from '@sbrc/utils';

export const getMotionPlanner = memoize(() => {
  if (typeof window === 'undefined') {
    throw new Error('Cannot create server-side motion planner!');
  }

  // the backend proxies through to real motion planner
  const motionPlannerEndpoint = `${API_ENDPOINT}motion-planner`;

  // TODO: Update the backend for the visualizer
  const webSocketMotionPlanner = new WebSocketMotionPlanner(
    motionPlannerEndpoint,
    null,
  );

  const rosMotionPlanner = new RosMotionPlanner();

  return new HybridMotionPlanner(rosMotionPlanner, webSocketMotionPlanner);
});
