import * as zod from 'zod';

import { ActionRequiredError, Step } from '@sb/remote-control/types';
import convertJavaScriptExpressionToUser from '@sb/remote-control/util/expressions/convertJavaScriptExpressionToUser';
import { convertPythonExpression } from '@sb/remote-control/util/expressions/v2';
import { validateCodeExpression } from '@sb/remote-control/util/expressions/validateCodeExpression';
import { Expression, describeExpression } from '@sb/routine-runner';

export namespace SetEnvironmentVariableStep {
  export const name = 'Set variable';
  export const description = 'Sets the value of an environment variable';
  export const librarySection = Step.LibrarySection.Control;
  export const librarySort = '5';
  export const argumentKind = 'SetEnvironmentVariable';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    variableName: zod.string().optional(),
    variableID: zod.string().optional(),
    newValue: Expression.optional(),
    isGlobal: zod.boolean().optional().default(false),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const validator: Step.Validator = ({ stepConfiguration }) => {
    const args = stepConfiguration?.args;

    if (args?.argumentKind !== argumentKind) {
      return;
    }

    if (!args.variableName) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Variable Name required',
        fieldId: 'variableName',
      });
    }

    if (!args.variableID) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Variable ID required',
        fieldId: 'variableID',
      });
    }

    if (!args.newValue) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Value is required',
        fieldId: 'newValue',
      });
    }

    validateCodeExpression(args.newValue, 'newValue', 'New value');
  };

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    if (!args.variableName) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Variable Name required',
        fieldId: 'variableName',
      });
    }

    if (!args.variableID) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Variable ID required',
        fieldId: 'variableID',
      });
    }

    if (!args.newValue) {
      throw new ActionRequiredError({
        kind: 'invalidConfiguration',
        message: 'Value is required',
        fieldId: 'newValue',
      });
    }

    return {
      ...stepData,
      stepKind: 'SetEnvironmentVariable',
      args: {
        variableName: args.variableName,
        variableID: args.variableID,
        newValue: args.newValue,
        isGlobal: args.isGlobal,
      },
    };
  };

  export const getStepDescription: Step.GetStepDescription = ({
    stepConfiguration: { args },
    routine,
    includeStepName,
    globalVariables,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      return null;
    }

    const { variableID, newValue } = args;

    const allVariables = [
      ...(globalVariables || []),
      ...(routine?.environmentVariables || []),
    ];

    const variable = allVariables.find((v) => v.id === variableID);

    if (variable && newValue) {
      if (newValue.kind === 'JavaScript') {
        const jsExprStr = convertJavaScriptExpressionToUser(
          newValue.expression,
          {
            isForStepDescription: true,
          },
        );

        return `${includeStepName ? 'Set variable ' : ''}${
          variable.name
        } to value ${jsExprStr}`;
      }

      if (newValue.kind === 'Python') {
        const { code: pyExprStr } =
          convertPythonExpression.withRemoteControlVariables.toUserDescription(
            newValue.expression,
          );

        return `${includeStepName ? 'Set variable ' : ''}${
          variable.name
        } to value ${pyExprStr}`;
      }

      return `${includeStepName ? 'Set variable ' : ''}${
        variable.name
      } to value ${describeExpression(newValue)}`;
    }

    return null;
  };
}

SetEnvironmentVariableStep satisfies Step.StepKindInfo;
