import {
  ActionRequiredError,
  type ConditionalArgument,
} from '@sb/remote-control/types';
import type { Conditional, Expression } from '@sb/routine-runner';

/**
 * Convert a comparison between two values from the conditionals builder into
 * the format used by the routine runner.
 *
 * A single comparison is represented in the conditionals builder like this:
 * [conditionA], which could be translated into something like this:
 *
 * ```ts
 * const check = currentActivity === 'movingArm';
 * ```
 */
const generateSingleComparisonOperation = (
  condition: ConditionalArgument.ConditionalBuilderState,
): Conditional => {
  const { operator, value, variable } = condition;

  if (!operator || !variable || value === null || value === undefined) {
    throw new ActionRequiredError({
      kind: 'invalidConfiguration',
      message: 'Condition is not fully configured.',
    });
  }

  if (
    variable.kind === 'space' ||
    variable.kind === 'environment' ||
    variable.variableKind === 'function'
  ) {
    throw new ActionRequiredError({
      kind: 'invalidConfiguration',
      message: `Comparing ${variable.kind} variables is not supported`,
    });
  }

  const valueExpression: Expression =
    typeof value === 'object' ? value : { kind: 'constant', value };

  if (variable.kind === 'ioState') {
    return {
      operator: operator.operator,
      leftOperand: { kind: 'stateVariable', path: variable.variableName },
      rightOperand: valueExpression,
    };
  }

  if (variable.kind === 'jointPose') {
    return {
      operator: operator.operator,
      leftOperand: { kind: 'stateVariable', path: variable.variableName },
      rightOperand: valueExpression,
    };
  }

  if (variable.kind === 'sensorState') {
    return {
      operator: operator.operator,
      leftOperand: { kind: 'sensor', sensorID: variable.sensorID },
      rightOperand: valueExpression,
    };
  }

  if (variable.kind === 'gripperState') {
    return {
      operator: operator.operator,
      leftOperand: {
        kind: 'stateVariable',
        path: `kinematicState.${variable.selector}`,
      },
      rightOperand: valueExpression,
    };
  }

  if (variable.kind === 'expression') {
    return {
      operator: '==',
      leftOperand: valueExpression,
      rightOperand: { kind: 'constant', value: true },
    };
  }

  return {
    operator: operator.operator,
    leftOperand: {
      kind: 'variable',
      stepID: variable.stepID,
      name: variable.variableName,
    },
    rightOperand: valueExpression,
  };
};

/**
 * Convert "AND" statements from the conditionals builder
 * into the format used by the routine runner.
 */
const generateConditionalAndOperation = (
  conditionalsList: ConditionalArgument.ConditionalBuilderState[],
): Conditional => {
  /**
   * If we only have one item in the array, then we only have one condition, so
   * it's not an AND statement. This is represented in the conditionals builder
   * by [conditionA], which could be translated into something like this:
   *
   * ```ts
   * const check = currentIteration == 0;
   * ```
   */
  if (conditionalsList.length === 1) {
    return generateSingleComparisonOperation(conditionalsList[0]);
  }

  /**
   * When we have exact two items in the array, then we know we have two single conditions only.
   * This is represented in the conditionals builder by [conditionA, conditionB], which could
   * be translated into something like this:
   *
   * ```ts
   * const conditionA = currentIteration > 4;
   * const conditionB = currentIteration < 10;
   * const check = conditionA && conditionB;
   * ```
   */
  if (conditionalsList.length === 2) {
    return {
      operator: 'AND',
      leftOperand: {
        kind: 'conditional',
        ...generateSingleComparisonOperation(conditionalsList[0]),
      },
      rightOperand: {
        kind: 'conditional',
        ...generateSingleComparisonOperation(conditionalsList[1]),
      },
    };
  }

  /**
   * When we have more than two conditions, then we need to recursively create new
   * AND operations until all conditions are converted into the routine runner format.
   */
  const [first, second, ...rest] = conditionalsList;

  return {
    operator: 'AND',
    leftOperand: {
      kind: 'conditional',
      ...generateConditionalAndOperation([first, second]),
    },
    rightOperand: {
      kind: 'conditional',
      ...generateConditionalAndOperation(rest),
    },
  };
};

/**
 * The conditionals builder in the frontend uses a two-dimensional array
 * to display a list of conditions. This helper function converts its
 * format to the one used by the routine runner.
 */
export const buildConditional = (
  conditionalsBuilder: ConditionalArgument.ConditionalBuilderState[][],
): Conditional => {
  /**
   * Having only item in the conditionals builder means we don't have
   * any OR statements since each OR condition is an element in the array.
   *
   * For example: [[conditionA], [conditionB]] would be an OR statement.
   */
  if (conditionalsBuilder.length === 1) {
    /**
     * If the element has more than one condition, then we have an AND
     * statement. For example, [conditionA, conditionB] would be the same
     * as conditionA && conditionB.
     */
    const isAndStatement = conditionalsBuilder[0].length > 1;

    if (isAndStatement) {
      return generateConditionalAndOperation(conditionalsBuilder[0]);
    }

    return generateSingleComparisonOperation(conditionalsBuilder[0][0]);
  }

  /**
   * When the conditionals builder has only two items, then we know we
   * only have one OR statement since [[conditionA], [conditionB]] means
   * the same as conditionA || conditionB.
   */
  if (conditionalsBuilder.length === 2) {
    const getLeftOperand = (): Expression => {
      const isAndStatement = conditionalsBuilder[0].length > 1;

      const operands = isAndStatement
        ? generateConditionalAndOperation(conditionalsBuilder[0])
        : generateSingleComparisonOperation(conditionalsBuilder[0][0]);

      return { kind: 'conditional', ...operands };
    };

    const getRightOperand = (): Expression => {
      const isAndStatement = conditionalsBuilder[1].length > 1;

      const operands = isAndStatement
        ? generateConditionalAndOperation(conditionalsBuilder[1])
        : generateSingleComparisonOperation(conditionalsBuilder[1][0]);

      return { kind: 'conditional', ...operands };
    };

    return {
      operator: 'OR',
      leftOperand: getLeftOperand(),
      rightOperand: getRightOperand(),
    };
  }

  /**
   * When we have more then two OR statements, then we need to recursively find
   * run this function to generate the next conditions until the entire tree
   * is generated.
   */
  const [first, second, ...rest] = conditionalsBuilder;

  return {
    operator: 'OR',
    leftOperand: {
      kind: 'conditional',
      ...buildConditional([first, second]),
    },
    rightOperand: {
      kind: 'conditional',
      ...buildConditional(rest),
    },
  };
};
