import { map } from 'rxjs/operators';

import { mapPaginated } from '@sb/feathers-types';
import type { Unsubscribe } from '@sb/remote-control/types';
import { convertGlobalSpaceResponse } from '@sb/remote-control/util/convertGlobalSpaceResponse';
import type { Space } from '@sb/routine-runner';

import { getGlobalSpaceService } from './utils/getGlobalSpaceService';

export function onGetAllGlobalSpace(
  onSnapshot: (
    globalSpaceItemsWithID: { globalSpaceID: string; spaceItem: Space.Item }[],
  ) => void,
): Unsubscribe {
  const subscription = getGlobalSpaceService()
    .watch()
    .find({ query: {} })
    .pipe(
      map((response) => {
        const globalSpaceItemsWithID = mapPaginated(
          response,
          convertGlobalSpaceResponse,
        );

        return globalSpaceItemsWithID.filter(
          (item): item is NonNullable<typeof item> => item !== null,
        );
      }),
    )
    .subscribe(onSnapshot);

  return () => subscription.unsubscribe();
}
